import { format, getUnixTime } from 'date-fns';
import { addHours } from 'date-fns/esm';
import { sortBy } from 'ramda';
import React, { useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useAssetComments, usePostAssetComments } from '../../queries/assets';

export default function CommentsModal({
    onClose,
    assetId,
    siteId,
}: {
    assetId: string;
    siteId: string;
    onClose: () => void;
}) {
    const assetComments = useAssetComments(assetId);
    const postAssetComments = usePostAssetComments(siteId, assetId);
    const [comment, setComment] = useState('');
    const commentsRef = useRef<HTMLDivElement>(null);

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        postAssetComments.execute(comment);
        setComment('');
        if (commentsRef.current) {
            commentsRef.current.scrollTo({
                top: commentsRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }

    return (
        <div className="fixed inset-0 flex flex-col bg-black bg-opacity-75 text-white z-50">
            <div className="flex items-center mt-1">
                <button
                    type="button"
                    className="ml-2 h-12 w-12 inline-flex items-center justify-center"
                    onClick={onClose}
                >
                    <span className="material-icons text-3xl">arrow_back</span>
                </button>
                <h2 className="text-xl ml-2">Comments</h2>
            </div>
            <div className="flex-1 mx-2 overflow-auto" ref={commentsRef}>
                {sortBy(
                    (c) =>
                        getUnixTime(c.createdAt ? addHours(new Date(c.createdAt), 2) : new Date()),
                    assetComments.data || []
                ).map((c) => (
                    <div key={c.id} className="my-2">
                        <div className="text-gray-400 text-xs flex items-center">
                            <div className="text-primary text-xs mr-2">
                                {c.createdBy?.firstName || ''} {c.createdBy?.lastName || ''}
                            </div>
                            {format(
                                // TODO: should better handle UTC
                                c.createdAt ? addHours(new Date(c.createdAt), 2) : new Date(),
                                'd MMM yyyy - hh:mm'
                            )}
                        </div>
                        <div>{c.message}</div>
                    </div>
                ))}
            </div>
            <form onSubmit={onSubmit} className="m-2 flex items-end">
                <TextareaAutosize
                    className="text-black p-2 rounded bg-white flex-1"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <button
                    type="submit"
                    className="rounded-full ml-2 bg-primary flex items-center justify-center"
                    style={{ minHeight: 40, maxHeight: 40, minWidth: 40, maxWidth: 40 }}
                >
                    <span className="material-icons">send</span>
                </button>
            </form>
        </div>
    );
}
