import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import Loader from './Loader';
import { SiteResponse, useSites } from '../queries/sites';
import useDisableBodyScroll from '../helpers/use-disable-body-scroll';
import DatePicker from './DatePicker';
import { useSubmitAssets } from '../queries/assets';
import ConfirmModal from './ConfirmModal';
import PhotoAndTagsManagement from './PhotoAndTagsManagement';
import useAsync from '../helpers/use-async';

type Image = File & { preview: string };

export default function PhotoUpload({
    images,
    clearImage,
    addImages,
    isOpen,
    onClose,
    site: existingSite,
}: {
    images: Image[];
    site?: SiteResponse;
    isOpen: boolean;
    clearImage: (index: number) => void;
    onClose: (clearImages: boolean) => void;
    addImages: (images: Image[]) => void;
}) {
    const { sites } = useSites();
    const { enable, disable } = useDisableBodyScroll(true);
    const [progress, setProgress] = useState(0);
    const [siteId, setSiteId] = useState<string | undefined>(existingSite?.id);
    const site = sites.data?.find((s) => s.id === siteId);
    const [hasLocation, setHasLocation] = useState<boolean | undefined>(undefined);
    const [takenAt, setTakenAt] = useState(new Date());

    const [tags, setTags] = useState<string[][] | undefined>(undefined);

    const [confirmModal, setConfirmModal] = useState(false);

    const submitAssets = useSubmitAssets(site?.id);

    useEffect(() => {
        if (isOpen) {
            disable();
        } else {
            enable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    React.useEffect(() => {
        if (!images) {
            setTags(undefined);
        } else if (!tags) {
            setTags(images.map(() => []));
        }
    }, [images, tags]);

    const getLocation = React.useCallback(() => {
        const { geolocation } = navigator;

        geolocation.getCurrentPosition(
            (position) => {
                const nearestSite = sites.data?.reduce<{ dist?: number; site?: SiteResponse }>(
                    (res, s) => {
                        const lat = (position.coords.latitude - s.latitude) ** 2;
                        const long = (position.coords.longitude - s.longitude) ** 2;
                        const dist = Math.sqrt(lat + long);
                        if (!res.dist || !res.site || dist < res.dist) {
                            return { dist, site: s };
                        }
                        return res;
                    },
                    {}
                );
                setSiteId(nearestSite?.site?.id);
                setHasLocation(true);
            },
            () => {
                if (sites && sites.data) {
                    setSiteId(sites.data[0].id);
                    setHasLocation(false);
                }
            }
        );
    }, [sites]);

    React.useEffect(() => {
        if (!site) {
            getLocation();
        }
    }, [getLocation, site]);

    const [selectedImage, setSelectedImage] = useState(0);

    const selectedTags = tags && tags[selectedImage];

    const { execute: submitImages, loading } = useAsync(async () => {
        await submitAssets({
            images,
            tags: tags as string[][],
            callback: (i) => {
                setProgress(i + 1 > images.length ? images.length : i + 1);
            },
            takenAt,
        });
        onClose(true);
    });

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            addImages(
                acceptedFiles.map((af) =>
                    Object.assign(af, {
                        preview: URL.createObjectURL(af),
                    })
                )
            );
            setTags([...(tags || []), ...acceptedFiles.map(() => [])]);
        },
        [addImages, tags]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
        accept: 'image/*',
    });

    const valid = images?.length > 0;

    if (!isOpen) return null;

    return (
        <div className="flex fixed inset-0 z-30 bg-base">
            {loading && (
                <Loader page>
                    <div className="mt-2">
                        {progress}/{images.length}
                    </div>
                </Loader>
            )}
            {confirmModal && (
                <ConfirmModal
                    onCancel={() => setConfirmModal(false)}
                    onExit={() => {
                        setConfirmModal(false);
                        onClose(false);
                    }}
                />
            )}
            <div className="h-full w-full max-w-3xl mx-auto flex flex-col">
                <div className="flex items-center mx-3 my-2">
                    <button
                        type="button"
                        className="p-3 mx-1 flex items-center"
                        onClick={() => {
                            if (images.length > 1) setConfirmModal(true);
                            else onClose(false);
                        }}
                    >
                        <span className="material-icons">keyboard_backspace</span>
                    </button>
                    <h1 className="text-lg">
                        Uploaded image{images.length > 1 ? `s (${images.length})` : ''}
                    </h1>
                </div>
                <div className="flex-1 overflow-y-auto">
                    <PhotoAndTagsManagement
                        imageUrls={images.map((i) => i.preview)}
                        setSelectedImage={setSelectedImage}
                        selectedImage={selectedImage}
                        clearImage={(i) => {
                            clearImage(i);
                        }}
                        site={site}
                        selectedTags={selectedTags}
                        tags={tags}
                        setTags={setTags}
                    />

                    {/* Fixed camera button */}
                    <div className="fixed bottom-0 right-0 m-4 z-20">
                        <button
                            type="button"
                            {...getRootProps()}
                            className="bg-primary h-16 w-16 rounded-full flex items-center justify-center cursor-pointer outline-none shadow-lg"
                        >
                            <input {...getInputProps()} />
                            <span className="material-icons text-3xl">camera_alt</span>
                        </button>
                    </div>

                    {/* Choose site */}
                    <div className="mt-4 mb-3 mx-3">
                        <h2 className="text-lg mt-4">Site</h2>
                        {hasLocation === false && (
                            <div>
                                <div className="p-1 bg-red-900 rounded inline-block font-sm bg-opacity-25">
                                    Your location has been{' '}
                                    <button
                                        type="button"
                                        className="underline focus:outline-none"
                                        onClick={() => getLocation()}
                                    >
                                        disabled
                                    </button>
                                    .
                                </div>
                            </div>
                        )}
                        <select
                            className="bg-base p-1 font-thin cursor-pointer"
                            value={siteId}
                            onChange={(event) => {
                                setSiteId(event.target.value);
                            }}
                        >
                            {sites.data?.map((s) => (
                                <option key={s.id} value={s.id}>
                                    {s.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mx-2 mt-2 mb-4">
                        <h2 className="text-lg mb-1 mx-1">Taken At</h2>
                        <DatePicker
                            value={takenAt}
                            label=""
                            onChange={setTakenAt}
                            maxDate={new Date()}
                        />
                    </div>

                    {/* Upload button */}
                    <button
                        type="button"
                        className={clsx(
                            'ml-3 mt-1 mb-3 px-3 py-2 rounded bg-blue-500 flex items-center',
                            {
                                'bg-opacity-25': !valid,
                            }
                        )}
                        disabled={!valid}
                        onClick={() => submitImages()}
                    >
                        Upload
                        <span className="material-icons ml-1">publish</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
