import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-unresolved
import { RootState } from 'd-man/dist/store/reducer';
import { mergeDeepLeft } from 'ramda';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { errorInterceptor, getAuthToken } from './store';
import { useIsMounted } from './utils';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL as string,
});

instance.interceptors.request.use((value) => value, errorInterceptor);

export function useAxios<T>(baseConfig: AxiosRequestConfig = {}) {
    const authToken = useSelector<RootState>(getAuthToken) as string;

    const isMounted = useIsMounted();

    const [data, setData] = useState<T | undefined>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(false);

    async function execute(config: AxiosRequestConfig = {}) {
        setLoading(true);
        try {
            const result = (await instance(
                mergeDeepLeft(
                    {
                        headers: {
                            Authorization: authToken,
                        },
                    },
                    mergeDeepLeft(baseConfig, config) as AxiosRequestConfig
                )
            )) as AxiosResponse<T>;
            if (isMounted()) {
                setData(result.data);
            }
            return result;
        } catch (err) {
            if (isMounted()) {
                setError(err);
            }
            throw err;
        } finally {
            if (isMounted()) {
                setLoading(false);
            }
        }
    }

    return { data, error, loading, execute, setLoading };
}
