import clsx from 'clsx';
import React from 'react';
import Loader from '../Loader';

export default function DeleteModal({
    onCancel,
    onDelete,
    loading,
}: {
    onCancel: () => void;
    onDelete: () => void;
    loading?: boolean;
}) {
    return (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
            <h3>Are you sure you want to delete this photo?</h3>
            <div className="flex space-x-4 mt-4">
                <button
                    type="button"
                    onClick={onCancel}
                    className="px-3 py-2 rounded m-1 bg-white text-black bg-opacity-75"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={onDelete}
                    disabled={loading}
                    className={clsx('px-3 py-2 rounded m-1 bg-red-500 flex items-center', {
                        'bg-opacity-25': loading,
                        'bg-opacity-75 hover:bg-opacity-100': !loading,
                    })}
                >
                    Delete
                    {loading && (
                        <div
                            className="relative w-8"
                            style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                        >
                            <Loader />
                        </div>
                    )}
                </button>
            </div>
        </div>
    );
}
