import { addMinutes, fromUnixTime, isBefore } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { useLogout } from '../helpers/use-logout';
import { domain } from '../store';

const TOKEN_EXPIRES_BUFFER = 2; // in minutes

export type LoginRequest = {
    email: string;
    password: string;
    longLived: boolean;
};
export type LoginResponse = {
    message: string;
    token: string;
    expiresAt: number;
};
export const login = domain.post<LoginRequest, LoginResponse>(`/authentication/login`);
export const useLogin = login.useHook;

export type AuthedUser = {
    // eslint-disable-next-line camelcase
    account_id: string;
    aud: string;
    email: string;
    exp: number;
    iat: number;
    id: string;
    iss: string;
    nbf: number;
    role: string;
    status: string;
};
export function useAuthedUser(disable = false) {
    const { data } = useLogin({ lazy: disable });
    const authedUser = data && jwtDecode<AuthedUser>(data.token);
    return authedUser;
}

const getRefresh = domain.get<LoginResponse>(`/authentication/refresh`, {
    storeLocation: {
        action: '/authentication/login',
        method: 'post',
    },
});

export const refresh = getRefresh.execute;

export function isTokenValid() {
    const state = getRefresh.getState();
    return (
        state.loading ||
        !state?.data?.expiresAt ||
        isBefore(addMinutes(new Date(), TOKEN_EXPIRES_BUFFER), fromUnixTime(state.data.expiresAt))
    );
}

type RegisterRequest = {
    companyName: string;
    firstName: string;
    lastName: string;
    city: string;
    jobRole: string;
    email: string;
    password: string;
};
type RegisterResponse = {
    userId: string;
    success: boolean;
    message: string;
    token: string;
};
export function useRegister() {
    return domain.usePost<RegisterRequest, RegisterResponse>(`/authentication/register`);
}

export type SiteInvitationResponse = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    invitedAt: string;
};
export function useSiteInvitation(token: string) {
    const isLoggedOut = useLogout();

    return domain.useGet<SiteInvitationResponse>(`/authentication/invitation/site?token=${token}`, {
        lazy: !isLoggedOut,
    });
}

export type AcceptInvitationRequest = {
    companyName: string;
    firstName: string;
    lastName: string;
    city: string;
    jobRole: string;
    email: string;
    password: string;
};
export type AcceptInvitationResponse = {
    userId: string;
    success: boolean;
    message: string;
    token: string;
};
export function useAcceptSiteInvitation(id?: string) {
    return domain.usePost<AcceptInvitationRequest, AcceptInvitationResponse>(
        `/authentication/invitation/site/${id}`,
        {
            storeLocation: {
                action: '/authentication/login',
                method: 'post',
            },
        }
    );
}

export function useForgotPassword() {
    return domain.usePost<{ email: string }, void>(`/authentication/forgotPassword`);
}

export type ResetPasswordRequest = {
    email: string;
    token: string;
    password: string;
};
export function useResetPassword() {
    return domain.usePost<ResetPasswordRequest, void>(`/authentication/resetPassword`);
}
