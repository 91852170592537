import { domain } from '../store';

export type JobRole = {
    code: string;
    name: string;
    active: boolean;
    createdAt: string;
};
export function useJobRoles() {
    return domain.useGet<JobRole[]>(`/references/jobRoles`);
}
