import React from 'react';
import Button from '../../../components/Button';
import Image from '../../../components/Image';
import ImageGrid from '../../../components/ImageGrid';
import { getImageThumb } from '../../../helpers/image';
import { useFavouriteAssets } from '../../../queries/assets';
import { useJobRoles } from '../../../queries/references';
import { Asset, useUserProfile } from '../../../queries/user';
import { EMPTY_GUID } from '../../../utils';

export default function Profile() {
    const userProfile = useUserProfile();
    const favouriteAssets = useFavouriteAssets();
    const jobRoles = useJobRoles();

    const me = userProfile?.data;

    return (
        <div>
            <div className="p-3">
                <div className="flex items-start space-x-4">
                    <Image
                        src={
                            me?.profilePicExtension &&
                            me?.profilePicId &&
                            getImageThumb(EMPTY_GUID, me?.profilePicId, me?.profilePicExtension)
                        }
                        size="100px"
                        alt="img"
                    />
                    <div className="ml-3">
                        <div className="text-2xl">
                            {me?.firstName || ''} {me?.lastName || ''}
                        </div>
                        <div className="text-gray-400 text-sm">
                            <div>{me?.city}</div>
                            <div>{me?.companyName}</div>
                            <div>
                                {jobRoles.data?.find((jr) => jr.code === me?.jobRole)?.name ||
                                    me?.jobRole}
                            </div>
                            <div className="text-primary">{me?.email}</div>
                            <div className="flex items-center space-x-2 mt-2 text-base">
                                <Button to="/profile/update">Edit</Button>
                                <Button to="/profile/password">Change Password</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-2">
                <div className="text-lg mt-2 mb-2">Need Help?</div>
                <p className="text-gray-400 text-sm">
                    Please let us know if you are experiencing any problems.
                </p>
                <a href="mailto:support@sitephoto.co.za" className="text-primary hover:underline">
                    support@sitephoto.co.za
                </a>
            </div>
            {(!favouriteAssets.data || favouriteAssets.data.length === 0) && (
                <div className="p-2">
                    <div className="text-lg mt-2 mb-2">Favourites</div>
                    <p className="text-gray-400 text-sm">
                        You do not currently have any favourite photos.
                    </p>
                </div>
            )}

            {favouriteAssets.data && favouriteAssets.data.length > 0 && (
                <ImageGrid
                    disableSectionDescription
                    sections={[
                        {
                            title: 'Favourites',
                            galleries: [
                                {
                                    id: '_',
                                    siteId: '_',
                                    siteName: '',
                                    startDate: new Date(),
                                    start: '',
                                    endDate: new Date(),
                                    createdBy: {
                                        id: '',
                                        profilePicId: '',
                                        firstName: '',
                                        lastName: '',
                                        jobRole: '',
                                        email: '',
                                        countryCode: '',
                                        isAdmin: false,
                                    },
                                    assetCount: {
                                        IMG: 1,
                                    },
                                    assets: favouriteAssets?.data
                                        ? favouriteAssets?.data.map((d) => d as unknown as Asset)
                                        : [],
                                },
                            ],
                        },
                    ]}
                />
            )}
        </div>
    );
}
