import React, { useRef, useState, useEffect } from 'react';
import * as R from 'ramda';
import { useUpsertSiteTags } from '../queries/assets';
import { SiteResponse } from '../queries/sites';
import TagHandler from './TagHandler';
import Checkbox from './Checkbox';
import { Loader } from '.';

export default function PhotoAndTagsManagement({
    imageUrls,
    selectedImage,
    setSelectedImage,
    clearImage,
    site,
    tags,
    selectedTags,
    setTags,
}: {
    imageUrls: string[];
    setSelectedImage: (index: number) => void;
    clearImage?: (index: number) => void;
    selectedImage: number;
    site: SiteResponse | undefined;
    tags: string[][] | undefined;
    selectedTags: string[] | undefined;
    setTags: (tags: string[][] | undefined) => void;
}) {
    const { execute: upsertSiteTags, loading: siteTagsLoading } = useUpsertSiteTags();
    const imagesRef = useRef<(HTMLButtonElement | null)[]>([]);

    const [tagAllPhotos, _setTagAllPhotos] = useState(false);
    function setTagAllPhotos(_tagAllPhotos: boolean) {
        /**
         * If setting tagAllPhotos to true, it should set all tags to the selectedImage's tags.
         */
        if (_tagAllPhotos && selectedTags) {
            setTags(tags?.map(() => selectedTags));
        }
        _setTagAllPhotos(_tagAllPhotos);
    }

    useEffect(() => {
        const imageRef = imagesRef.current[selectedImage];
        if (imageRef) {
            imageRef.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setSelectedTags(newSelectedTags: string[]) {
        if (tagAllPhotos) {
            setTags(tags?.map(() => newSelectedTags));
        } else {
            setTags(tags?.map((t, i) => (i === selectedImage ? newSelectedTags : t)));
        }
    }

    return (
        <>
            {siteTagsLoading && <Loader page />}

            {/* Image carousel */}
            <div className="flex overflow-x-auto mx-2">
                {imageUrls.map((imageUrl, i) => {
                    const isSelected = selectedImage === i;
                    return (
                        <button
                            ref={(ref) => {
                                imagesRef.current[i] = ref;
                            }}
                            type="button"
                            className={`focus:outline-none bg-center bg-cover relative border-2 m-1 ${
                                isSelected ? 'border-primary' : 'border-transparent'
                            }`}
                            style={{
                                backgroundImage: `url(${imageUrl})`,
                                minWidth: '120px',
                                maxWidth: '120px',
                                height: '120px',
                            }}
                            onClick={() => setSelectedImage(i)}
                            key={i}
                        >
                            {clearImage && isSelected && (
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                                <div
                                    className="focus:outline-none top-0 right-0 m-1 font-bold absolute"
                                    onClick={() => {
                                        clearImage(i);
                                        setTags(tags?.filter((_, ti) => ti !== i));
                                    }}
                                >
                                    <span className="material-icons bg-opacity-75 flex items-center justify-center material-icons bg-base text-lg h-6 w-6 rounded-full">
                                        close
                                    </span>
                                </div>
                            )}
                        </button>
                    );
                })}
            </div>

            {/* Tags management */}
            <div className="my-4 mx-3">
                <h2 className="text-lg">Tags</h2>
                <div className="-mx-2">
                    {site?.tags && selectedTags && (
                        <TagHandler
                            tags={site.tags}
                            selectedTags={selectedTags}
                            setSelectedTags={setSelectedTags}
                            disableEdit={!site?.isAdmin}
                            onTagAdd={async (newSiteTags, newTag) => {
                                await upsertSiteTags({
                                    siteId: site.id,
                                    tags: newSiteTags,
                                });
                                setSelectedTags([...selectedTags, newTag]);
                            }}
                            onTagDelete={async (newSiteTags, deletedTag) => {
                                await upsertSiteTags({
                                    siteId: site.id,
                                    tags: newSiteTags,
                                });
                                setSelectedTags(R.without([deletedTag], selectedTags));
                            }}
                        />
                    )}
                </div>
            </div>

            {/* Add tags to photos */}
            <div className="mx-2">
                <Checkbox
                    label="Add tags to all photos"
                    checked={tagAllPhotos}
                    setChecked={setTagAllPhotos}
                />
            </div>
        </>
    );
}
