import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Link, useHistory } from 'react-router-dom';
import { ActivitySection } from '../queries/user';
import { getImageThumb } from '../helpers/image';
import useBreakpoints from '../helpers/use-breakpoints';
import Image from './Image';
import Loader from './Loader';
import GalleryModal, { GalleryModalState } from './GalleryModal/GalleryModal';
import { getQueryString, useQuery } from '../utils';

function photosLabel(photoCount?: number) {
    if (!photoCount) return 'No Photos';
    if (photoCount === 1) return '1 Photo';
    return `${photoCount} Photos`;
}

export default function ImageGrid({
    sections,
    disableSectionDescription,
    disableLinks,
    onBottomScroll,
    disableLoading,
    emptyState,
    disableEdit,
    loading,
}: {
    onBottomScroll?: (hasChanged?: boolean) => void;
    sections: ActivitySection[] | undefined;
    disableLinks?: boolean;
    disableSectionDescription?: boolean;
    disableEdit?: boolean;
    /**
     * Since ImageGrid may be rendered in a tab that is not visible (but rendered), may disable loading
     * at bottom of the page.
     */
    disableLoading?: boolean;
    emptyState?: React.ReactNode;
    loading?: boolean;
}) {
    const history = useHistory();
    const loaderRef = useRef<HTMLDivElement>(null);

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { goBack } = history as any;

    const query = useQuery();

    useEffect(() => {
        if (onBottomScroll) {
            onBottomScroll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const galleryModalState = React.useMemo(() => {
        const { galleryId, assetId, siteId } = query;

        if (!galleryId || !assetId || !siteId) {
            return undefined;
        }
        return {
            galleryId,
            assetId,
            siteId,
        } as unknown as GalleryModalState;
    }, [query]);

    function setGalleryModalState(modalState: GalleryModalState | undefined) {
        if (modalState) {
            history.push(getQueryString({ ...query, ...modalState, modal: true }));
        } else {
            goBack();
        }
    }
    const [hasChanged, setHasChanged] = useState(false);

    useBottomScrollListener<HTMLDivElement>(
        async () => {
            if (!disableLoading && !loading && onBottomScroll) {
                onBottomScroll();
            }
        },
        { offset: 400, debounceOptions: 200, triggerOnNoScroll: false }
    );

    const { isLarger } = useBreakpoints();

    const imageSize = (() => {
        if (isLarger('md')) {
            return 'calc(100%/6)';
        }
        if (isLarger('sm')) {
            return 'calc((100vw - (100vw - 100%))/5)';
        }
        if (isLarger('xs')) {
            return 'calc((100vw - (100vw - 100%))/4)';
        }
        return 'calc((100vw - (100vw - 100%))/3)';
    })();

    return (
        <div>
            {galleryModalState && sections && (
                <GalleryModal
                    sections={sections}
                    galleryModalState={galleryModalState}
                    onClose={() => {
                        setGalleryModalState(undefined);
                        if (hasChanged && onBottomScroll) {
                            onBottomScroll();
                        }
                    }}
                    disableEdit={disableEdit}
                    siteId={galleryModalState.siteId}
                    setHasChanged={() => setHasChanged(true)}
                />
            )}
            {sections?.length === 0 && emptyState}
            {sections?.map((section) => (
                <div key={section.title} className="py-1">
                    <h3 className="text-white px-2 py-1 text-xl">{section.title}</h3>
                    {section.galleries.map((gallery) => {
                        const siteContent = !disableSectionDescription && (
                            <>
                                <div className="mx-2 font-thin text-sm -mb-1 text-gray-200">
                                    {photosLabel(gallery.assetCount.IMG)}
                                    <span className="mx-2 text-center">|</span>
                                    {gallery.siteName}
                                </div>
                                {gallery.createdBy && (
                                    <div className="mx-2 text-primary font-thin text-sm">
                                        Uploaded by{' '}
                                        {`${gallery.createdBy.firstName} ${gallery.createdBy.lastName}`}
                                        <span className="mx-2 text-center">|</span>
                                        {gallery.start}
                                    </div>
                                )}
                            </>
                        );
                        return (
                            <div key={gallery.id} className="mt-2 pb-2 border-b border-gray-800">
                                {disableLinks || disableSectionDescription ? (
                                    siteContent
                                ) : (
                                    <Link to={`/site/${gallery.siteId}`}>{siteContent}</Link>
                                )}
                                <div className="flex flex-wrap mt-1">
                                    {R.uniqBy((a) => a.id, gallery.assets).map((asset) => (
                                        <Image
                                            key={asset.id}
                                            size={imageSize}
                                            onClick={() =>
                                                setGalleryModalState({
                                                    galleryId: gallery.id,
                                                    siteId: asset.siteId || gallery.siteId,
                                                    assetId: asset.id,
                                                })
                                            }
                                            padded
                                            src={getImageThumb(
                                                asset.siteId || gallery.siteId,
                                                asset.id,
                                                asset.extension
                                            )}
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
            {loading && (
                <div
                    ref={loaderRef}
                    className="h-24 relative mb-20"
                    style={{ transform: 'scale(0.5)' }}
                >
                    <Loader />
                </div>
            )}
        </div>
    );
}
