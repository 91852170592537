import React, { useState } from 'react';
import * as R from 'ramda';
import clsx from 'clsx';

export default function TagHandler({
    tags,
    selectedTags,
    setSelectedTags,
    setTags,
    onTagAdd,
    onTagDelete,
    disableEdit: _disabledEdit,
    disableAll,
}: {
    tags: string[];
    selectedTags?: string[];
    setSelectedTags?: (selectedTags: string[]) => void;
    setTags?: (tags: string[]) => void;
    onTagAdd?: (newSiteTags: string[], newTag: string) => void;
    onTagDelete?: (newSiteTags: string[], deletedTag: string) => void;
    disableEdit?: boolean;
    disableAll?: boolean;
}) {
    const disableEdit = _disabledEdit || disableAll;

    const [modal, setModal] = useState<'add' | string | undefined>(undefined);
    const [newTag, setNewTag] = useState('');

    function deleteTag(tag: string) {
        if (onTagDelete) onTagDelete(R.without([tag], tags), tag);
        if (setTags) setTags(R.without([tag], tags));
        if (setSelectedTags && selectedTags) setSelectedTags(R.without([tag], selectedTags));
        setModal(undefined);
    }

    function toggleTag(tag: string) {
        if (setSelectedTags && selectedTags) {
            setSelectedTags(
                R.includes(tag, selectedTags)
                    ? R.without([tag], selectedTags)
                    : R.append(tag, selectedTags)
            );
        }
    }

    function addNewTag() {
        if (setTags) setTags(R.append(newTag, tags));
        if (onTagAdd) onTagAdd(R.append(newTag, tags), newTag);
        setModal(undefined);
        setNewTag('');
    }

    const content = (() => {
        if (modal === 'add') {
            return (
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
                    <h3 className="mb-2">Add new tag</h3>
                    <input
                        className="bg-white bg-opacity-25 p-2 rounded text-white"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                    />
                    <div className="flex space-x-4 mt-4">
                        <button
                            type="button"
                            onClick={() => setModal(undefined)}
                            className="px-3 py-2 rounded bg-white text-black bg-opacity-75"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={addNewTag}
                            className="px-3 py-2 rounded bg-blue-500"
                        >
                            Add
                        </button>
                    </div>
                </div>
            );
        }
        // if delete
        if (modal) {
            return (
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
                    <h3 className="mb-2">Are you sure you want to remove this tag?</h3>
                    <div className="flex space-x-4 mt-4">
                        <button
                            type="button"
                            onClick={() => setModal(undefined)}
                            className="px-3 py-2 rounded bg-white text-black bg-opacity-75"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={() => deleteTag(modal)}
                            className="px-3 py-2 rounded bg-red-500"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            );
        }
        return null;
    })();

    return (
        <div className="flex mt-2 flex-wrap">
            {content}
            {tags.map((t) => {
                const isSelected = selectedTags?.includes(t);
                return (
                    <button
                        key={t}
                        type="button"
                        onClick={() => !disableAll && toggleTag(t)}
                        className={clsx(`px-3 py-2 rounded m-1 relative`, {
                            'bg-primary': isSelected,
                            'bg-white bg-opacity-25': !isSelected,
                            'pr-8': isSelected && !disableEdit,
                            'cursor-default': disableAll,
                        })}
                    >
                        {t}

                        {isSelected && !disableEdit && (
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                            <div
                                className="focus:outline-none top-0 right-0 m-1 font-bold absolute"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setModal(t);
                                }}
                            >
                                <span className="material-icons">close</span>
                            </div>
                        )}
                    </button>
                );
            })}
            {!disableEdit && (
                <button
                    type="button"
                    onClick={() => setModal('add')}
                    className="px-3 py-2 rounded m-1 relative border border-gray-400 border-dashed font-bold"
                >
                    + new tag
                </button>
            )}
        </div>
    );
}
