import React from 'react';

export default function useDisableBodyScroll(manual = false) {
    const disable = () => {
        document.body.style.overflow = 'hidden';
    };

    const enable = () => {
        document.body.style.overflow = 'unset';
    };

    React.useEffect(() => {
        if (!manual) {
            disable();
            return enable;
        }
        return undefined;
    }, [manual]);

    return { disable, enable };
}
