import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useBreakpoint from '../../helpers/use-breakpoints';
import tabs from './tabs';

export default function Tabber({
    tabIndex,
    onChange,
}: {
    tabIndex: number;
    onChange: (tabIndex: number) => void;
}) {
    const history = useHistory();

    const { isLarger } = useBreakpoint();

    const isMobile = !isLarger('sm');

    const divisionWidth = `${isMobile ? 100 / tabs.length : 120}${isMobile ? '%' : 'px'}`;

    return (
        <div className="flex relative flex-1">
            {tabs.map((tab, ti) => (
                <button
                    onClick={() => {
                        onChange(ti);
                        history.push({ search: `?tab=${tab.link}` });
                    }}
                    type="button"
                    key={tab.link}
                    style={{ width: divisionWidth }}
                    className={clsx('pt-2 pb-3 text-lg text-center', {
                        'text-primary': ti === tabIndex,
                    })}
                >
                    {tab.name}
                </button>
            ))}
            <div
                className="h-1 bg-primary absolute bottom-0 left-0"
                style={{
                    marginLeft: `calc(${tabIndex} * ${divisionWidth})`,
                    transition: 'margin 0.2s',
                    width: divisionWidth,
                }}
            />
        </div>
    );
}
