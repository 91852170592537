import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import Image from './Image';

export default function ImageInput({
    value,
    label,
    onChange,
    defaultImage,
    ...props
}: {
    value: File | undefined;
    label?: string | React.ReactNode;
    defaultImage?: string;
    type?: 'text' | 'password' | 'email' | 'number';
    onChange?: (file: File) => void;
    disabled?: boolean;
    [rem: string]: unknown;
}) {
    const className = clsx(props, 'inline-block');

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (onChange) {
                onChange(acceptedFiles[0]);
            }
        },
        [onChange]
    );

    const imagePreview = useMemo(
        () => (value && URL.createObjectURL(value)) || defaultImage,
        [defaultImage, value]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: 'image/*',
    });

    return (
        <label className={className}>
            {label && <span className="block text-opacity-75 text-white font-thin">{label}</span>}
            <button type="button" {...getRootProps()} className="h-40 w-40">
                <input {...getInputProps()} />
                {imagePreview ? (
                    <Image src={imagePreview} size="160px" alt="img" />
                ) : (
                    <div className="flex items-center justify-center border-gray-600 w-full h-full border border-dashed">
                        {' '}
                        Add image
                    </div>
                )}
            </button>
        </label>
    );
}
