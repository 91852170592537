import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/images/logo512.png';
import { Input, Loader } from '../../../components';
import Select from '../../../components/Select';
import { useAcceptSiteInvitation, useSiteInvitation } from '../../../queries/authentication';
import { useJobRoles } from '../../../queries/references';
import { useQuery } from '../../../utils';

export default function AcceptSiteInvitation() {
    const query = useQuery();

    const { token } = query;

    const siteInvitation = useSiteInvitation(token as string);
    const acceptSiteInvitation = useAcceptSiteInvitation(siteInvitation?.data?.id);
    const jobRoles = useJobRoles();

    const [loaded, setLoaded] = useState(false);
    const [form, setForm] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        city: '',
        jobRole: '',
        email: '',
        password: '',
    });

    useEffect(() => {
        if (siteInvitation.data && !loaded) {
            setForm({
                ...form,
                firstName: siteInvitation.data.firstName,
                lastName: siteInvitation.data.lastName,
                email: siteInvitation.data.email,
            });
            setLoaded(true);
        }
    }, [form, loaded, siteInvitation.data]);

    const history = useHistory();

    async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const [err] = await acceptSiteInvitation.execute(form);
        if (!err) {
            history.push('/');
        }
    }

    const valid = (() => {
        if (!siteInvitation.data || acceptSiteInvitation.loading) {
            return false;
        }
        return !!(form.firstName && form.lastName && form.email && form.jobRole && form.password);
    })();

    if (siteInvitation.error) {
        return (
            <div className="flex flex-col items-center">
                <h1 className="text-4xl text-center text-white mt-12 mb-6">SitePhoto</h1>
                <div className="bg-red-600 bg-opacity-25 p-2 rounded">
                    Your token has expired or is incorrect
                </div>
            </div>
        );
    }

    return (
        <div className="flex">
            <form
                onSubmit={onFormSubmit}
                className="w-72 max-w-full mx-auto px-2 pt-8 pb-4 text-lg"
            >
                <img src={logo} alt="logo" className="h-32 mx-auto" />
                <h1 className="text-4xl text-center text-white mt-3 mb-6">SitePhoto</h1>
                {acceptSiteInvitation.error && (
                    <div className="text-red-600 my-3">{acceptSiteInvitation.error}</div>
                )}
                <Input
                    label="Company"
                    mt-3
                    value={form.companyName}
                    onChange={(e) => setForm({ ...form, companyName: e.target.value })}
                />
                <Input
                    label="First Name"
                    mt-3
                    value={form.firstName}
                    onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                />
                <Input
                    label="Last Name"
                    mt-3
                    value={form.lastName}
                    onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                />
                <Input
                    label="City"
                    mt-3
                    value={form.city}
                    onChange={(e) => setForm({ ...form, city: e.target.value })}
                />
                <Select
                    label="Job Role"
                    options={
                        jobRoles.data?.map((jr) => ({
                            value: jr.code,
                            name: jr.name,
                        })) || []
                    }
                    mt-3
                    value={form.jobRole}
                    onChange={(e) => setForm({ ...form, jobRole: e.target.value })}
                />
                <Input
                    label="Email address"
                    mt-3
                    type="email"
                    value={form.email}
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
                <Input
                    label="Password"
                    type="password"
                    mt-3
                    value={form.password}
                    onChange={(e) => setForm({ ...form, password: e.target.value })}
                />
                <button
                    type="submit"
                    disabled={!valid}
                    className={clsx(
                        'mt-4 mb-2 button bg-primary py-2 px-4 rounded flex items-center',
                        { 'bg-opacity-25': !valid, 'bg-opacity-75 hover:bg-opacity-100': valid }
                    )}
                >
                    Sign up
                    {acceptSiteInvitation.loading ? (
                        <div
                            className="relative w-8"
                            style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                        >
                            <Loader />
                        </div>
                    ) : (
                        <span className="material-icons ml-2">arrow_right_alt</span>
                    )}
                </button>
            </form>
        </div>
    );
}
