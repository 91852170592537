import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useInviteMember, useSites } from '../../queries/sites';
import SiteWrapper from './SiteWrapper';
import { Input, Loader } from '../../components';
import { useAllMembers } from './useAllsiteMembers';

export default function AddSiteMember() {
    const { sites } = useSites();
    const params = useParams<{ id: string }>();
    const site = sites.data?.find((s) => s.id === params.id);
    const history = useHistory();
    const inviteMember = useInviteMember(site?.id);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [showExistingMembers, setShowExistingMembers] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const allMembers = useAllMembers();

    useEffect(() => {
        if (sites && !site) {
            history.push('/');
        }
    }, [history, site, sites]);

    async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const invitePromises = selectedMembers.map((member) =>
            inviteMember.execute({
                firstName: member.firstName,
                lastName: member.lastName,
                email: member.email.toLowerCase(),
                asAdmin: false,
            })
        );

        if (firstName && lastName && email) {
            invitePromises.push(
                inviteMember.execute({
                    firstName,
                    lastName,
                    email: email.toLowerCase(),
                    asAdmin: false,
                })
            );
        }

        await Promise.all(invitePromises);
        history.push(`/site/${site?.id}/members`);
    }

    const valid = (() => {
        if (!site || inviteMember.loading) {
            return false;
        }
        return !!(firstName && lastName && email) || selectedMembers.length > 0;
    })();

    function handleAddExistingMember() {
        setShowExistingMembers(!showExistingMembers);
    }

    function handleAddSelectedMembers() {
        const invitePromises = selectedMembers.map((member) =>
            inviteMember.execute({
                firstName: member.firstName,
                lastName: member.lastName,
                email: member.email.toLowerCase(),
                asAdmin: false,
            })
        );

        Promise.all(invitePromises).then(() => {
            setSelectedMembers([]);
            setShowExistingMembers(false);
            history.push(`/site/${site?.id}/members`);
        });
    }

    function handleMemberClick(member: any) {
        if (selectedMembers.includes(member)) {
            setSelectedMembers(selectedMembers.filter((m) => m !== member));
        } else {
            setSelectedMembers([...selectedMembers, member]);
        }
    }

    const filteredMembers = allMembers.filter((member) =>
        `${member.firstName} ${member.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <SiteWrapper siteName={site?.name}>
            {inviteMember.loading && <Loader overlay />}
            {site && (
                <div className="p-2 mt-1 flex flex-col relative">
                    <button
                        className={clsx(
                            'absolute top-0 right-0 ml-auto mr-3 px-3 py-2 mt-3 rounded bg-primary flex items-center transition duration-300 ease-in-out',
                            { 'bg-opacity-25': !valid }
                        )}
                        type="button"
                        onClick={handleAddExistingMember}
                    >
                        {showExistingMembers ? 'Hide Existing Members' : 'Show Existing Members'}
                    </button>
                    <form onSubmit={onFormSubmit} className="flex flex-col mt-8 space-y-4">
                        <div className="mb-1 text-lg font-semibold">Invite Member</div>
                        <Input
                            label="First Name"
                            mt-3
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <Input
                            label="Last Name"
                            mt-3
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <Input
                            label="Email Address"
                            mt-3
                            value={email.toLowerCase()}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            className={clsx(
                                'ml-auto mr-3 px-3 py-2 mt-3 rounded bg-primary flex items-center transition duration-300 ease-in-out',
                                { 'bg-opacity-25': !valid }
                            )}
                            type="submit"
                            disabled={!valid}
                        >
                            Create
                        </button>
                    </form>
                    {showExistingMembers && (
                        <div className="mt-8 space-y-4">
                            <div className="flex items-center justify-between mb-2">
                                <h3 className="text-xl font-semibold">Existing Members</h3>
                                {selectedMembers.length > 0 && (
                                    <button
                                        className="ml-3 px-3 py-2 rounded bg-primary flex items-center transition duration-300 ease-in-out"
                                        type="button"
                                        onClick={handleAddSelectedMembers}
                                    >
                                        {selectedMembers.length > 1
                                            ? 'Add Existing Members'
                                            : 'Add Existing Member'}
                                    </button>
                                )}
                            </div>
                            <Input
                                label="Search"
                                placeholder="Search members..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div className="space-y-2">
                                {filteredMembers.map((m) => (
                                    <div
                                        key={m.id}
                                        className={clsx(
                                            'flex items-center p-2 border-b cursor-pointer transition duration-300 ease-in-out rounded',
                                            {
                                                'border-2 border-primary':
                                                    selectedMembers.includes(m),
                                            }
                                        )}
                                        onClick={() => handleMemberClick(m)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleMemberClick(m);
                                            }
                                        }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <div className="mr-3">
                                            {m.firstName} {m.lastName}
                                        </div>
                                        <div className="text-gray-500">{m.email.toLowerCase()}</div>
                                        {selectedMembers.includes(m) && (
                                            <div className="ml-auto text-green-600">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M5 13l4 4L19 7"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </SiteWrapper>
    );
}
