import React from 'react';

export default function ConfirmModal({
    onCancel,
    message = 'You have pending photo uploads that may be lost, are you sure you want to close?',
    onExit,
}: {
    message?: string;
    onCancel: () => void;
    onExit: () => void;
}) {
    return (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
            <h3 className="text-center px-2">{message}</h3>
            <div className="flex space-x-4 mt-4">
                <button
                    type="button"
                    onClick={onCancel}
                    className="px-3 py-2 rounded m-1 bg-white text-black bg-opacity-75"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={onExit}
                    className="px-3 py-2 rounded m-1 bg-red-500 flex items-center bg-opacity-75 hover:bg-opacity-100"
                >
                    Confirm
                </button>
            </div>
        </div>
    );
}
