import React from 'react';
import { GoogleAPI } from 'google-maps-react';
import Login from './pages/Login';
import Main from './pages/Main';
import { LayoutKey } from './layout';
import Site from './pages/site';
import UpsertSite from './pages/site/UpsertSite';
import SiteMembers from './pages/site/SiteMembers';
import AddSiteMember from './pages/site/AddSiteMember';
import AcceptSiteInvitation from './pages/invitations/accept/AcceptSiteInvitation';
import UpdateSiteMember from './pages/site/UpdateSiteMember';
import SiteDetails from './pages/site/SiteDetails';
import DeletedSite from './pages/DeletedSite';
import UpdatePassword from './pages/profile/UpdatePassword';
import UpdateProfile from './pages/profile/UpdateProfile';
import SharedSite from './pages/site/Site/SharedSite';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

export type LocalRoute = {
    path: string;
    Component: React.FC<{ children: React.ReactNode; google: GoogleAPI }>;
    public?: boolean;
    routes?: LocalRoute[];
    layout?: LayoutKey;
};

/**
 * If a route is a sub-route of another, the parent must pass down the child route via its
 * children property.
 */
const routes: LocalRoute[] = [
    {
        path: '/login',
        Component: Login,
        public: true,
        layout: 'public',
    },
    {
        path: '/forgot-password',
        Component: ForgotPassword,
        public: true,
        layout: 'public',
    },
    {
        path: '/reset-password',
        Component: ResetPassword,
        public: true,
        layout: 'public',
    },
    {
        path: '/invitations/accept/site',
        Component: AcceptSiteInvitation,
        public: true,
    },
    {
        path: '/site/:id/members/add',
        Component: AddSiteMember,
    },
    {
        path: '/site/:siteId/members/:id',
        Component: UpdateSiteMember,
    },
    {
        path: '/site/:id/edit',
        Component: UpsertSite,
    },
    {
        path: '/site/:id/members',
        Component: SiteMembers,
    },
    {
        path: '/site/:id/details',
        Component: SiteDetails,
    },
    {
        path: '/sites/:id/shared',
        Component: SharedSite,
        public: true,
    },
    {
        path: '/site/add',
        Component: UpsertSite,
    },
    {
        path: '/site/:id',
        Component: Site,
    },
    {
        path: '/delete-site',
        Component: DeletedSite,
    },
    {
        path: '/profile/update',
        Component: UpdateProfile,
    },
    {
        path: '/profile/password',
        Component: UpdatePassword,
    },
    {
        path: '/',
        Component: Main,
    },
];

export default routes;
