import * as React from 'react';
import { Redirect, useLocation, matchPath } from 'react-router-dom';
import routes from '../routes';
import { useLogin } from '../queries/authentication';

export default function AuthWrapper({ children }: { children: React.ReactNode }): JSX.Element {
    const location = useLocation();

    const privateRoute = routes.find(
        (route) => !route.public && matchPath(location.pathname, { path: route.path, exact: true })
    );

    const auth = useLogin();

    if (privateRoute && !auth.data) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: location },
                }}
            />
        );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return <>{children}</>;
}
