/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Map, GoogleAPI, Marker } from 'google-maps-react';
import clsx from 'clsx';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { SiteResponse, useUpdateSiteLocation } from '../../../queries/sites';
import { FormData, SetFormField } from './UpsertSite';
import useAddressFromLocation, {
    flattenLatLng,
    Location,
    useLocationFromAddress,
} from './map-helpers';

export default function MapStep({
    formData,
    setFormField,
    site,
    google,
}: {
    formData: FormData;
    setFormField: SetFormField;
    site: SiteResponse | undefined;
    google: GoogleAPI;
}) {
    const { position, marker } = formData;

    /**
     * When local position loaded - but marker not yet defined, get marker from current position.
     */
    React.useEffect(() => {
        if (formData.position && !formData.marker) {
            setFormField('marker')({
                lat: formData.position.coords.latitude,
                lng: formData.position.coords.longitude,
            });
        }
    }, [formData.marker, formData.position, setFormField]);

    const siteLocation: Location = site ? { lat: site.latitude, lng: site.longitude } : undefined;

    const siteLatLng = flattenLatLng(siteLocation);
    const markerLatLng = flattenLatLng(formData.marker);

    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | undefined>(undefined);

    const updateSiteLocation = useUpdateSiteLocation(site?.id, formData.marker);

    const hasMarkerChanged = siteLatLng !== markerLatLng;

    async function updateLocation() {
        if (formData.marker) {
            updateSiteLocation.execute();
        }
    }
    const [autocomplete, setAutocomplete] = useState(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addressFromAutocomplete = (autocomplete as any)?.value?.description as string;
    const addressFromLocation = useAddressFromLocation(formData.marker);

    const address = addressFromLocation || addressFromAutocomplete;

    /**
     * If map has just loaded, you might not have access to position or marker.
     * Then it will center to default position.
     * If position is loaded, it should center to that.
     */
    useEffect(() => {
        if (position && !marker) {
            setMapCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
        }
    }, [marker, position]);

    const locationFromAutocomplete = useLocationFromAddress(addressFromAutocomplete);

    /**
     * When searching for new location, move marker on map position to that.
     */
    useEffect(() => {
        if (locationFromAutocomplete) {
            setFormField('marker')(locationFromAutocomplete);
            setMapCenter(locationFromAutocomplete);
        }
    }, [locationFromAutocomplete, setFormField]);

    return (
        <div className="mb-4">
            <div className="text-lg mt-3 mb-2">Location</div>
            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY as string}
                selectProps={{
                    value: autocomplete,
                    placeholder: 'Search...',
                    onChange: setAutocomplete,
                    styles: {
                        input: (provided: any) => ({
                            ...provided,
                            color: '#2f303e',
                        }),
                        option: (provided: any) => ({
                            ...provided,
                            color: '#2f303e',
                        }),
                        singleValue: (provided: any) => ({
                            ...provided,
                            color: '#2f303e',
                        }),
                        control: (provided: any) => {
                            return {
                                ...provided,
                                boxShadow: 'none',
                                borderWidth: '2px',
                                borderRadius: '0.25rem',
                            };
                        },
                    },
                }}
            />
            <div className="flex flex-col mt-2">
                <Map
                    google={google}
                    zoom={10}
                    draggable
                    onDragstart={() => setMapCenter(undefined)}
                    center={mapCenter}
                    initialCenter={(() => {
                        if (position) {
                            return {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                        }
                        if (marker) {
                            return marker;
                        }
                        return {
                            lat: Number.parseInt(process.env.REACT_APP_DEFAULT_LAT as string, 10),
                            lng: Number.parseInt(process.env.REACT_APP_DEFAULT_LNG as string, 10),
                        };
                    })()}
                    containerStyle={{
                        position: 'relative',
                        width: '100%',
                        height: '240px',
                    }}
                    onClick={(a, b, c) => {
                        setFormField('marker')({
                            lat: c.latLng.lat() as number,
                            lng: c.latLng.lng() as number,
                        });
                        setAutocomplete(null);
                    }}
                >
                    {marker && <Marker position={marker} title="Your site" />}
                </Map>
                <div className="text-md mt-2 text-gray-300 w-60 flex flex-col justify-evenly items-start">
                    <div>
                        {address ? (
                            address
                                .split(',')
                                .map((s) => s.trim())
                                .slice(0, 3)
                                .map((s, i) => <div key={i}>{s}</div>)
                        ) : (
                            <div className="flex flex-col space-y-1">
                                <div className="h-6 w-32 bg-gray-700 bg-opacity-75" />
                                <div className="h-6 w-24 bg-gray-700 bg-opacity-75" />
                                <div className="h-6 w-32 bg-gray-700 bg-opacity-75" />
                            </div>
                        )}
                    </div>
                    {site && (
                        <button
                            className={clsx('px-3 py-2 mt-3 rounded bg-primary flex items-center', {
                                'bg-opacity-25 cursor-default': !hasMarkerChanged,
                            })}
                            type="button"
                            onClick={updateLocation}
                            disabled={!hasMarkerChanged}
                        >
                            Update location
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
