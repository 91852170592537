import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Button({
    to,
    children,
    onClick,
    type: _type,
    ...props
}: {
    to?: string;
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent) => void;
    type?: 'button' | 'submit';
    [rem: string]: unknown;
}) {
    const type = _type || 'button';

    const defaultClassName = clsx(
        props,
        'outline-none bg-none py-0 border border-gray-400 inline-block px-3 rounded-lg text-gray-300'
    );

    const clickableClassName = clsx(
        defaultClassName,
        'cursor-pointer hover:border-gray-200 hover:text-white'
    );
    const unClickableClassName = clsx(defaultClassName, 'cursor-default');

    if (to) {
        return (
            <Link to={to} className={clickableClassName}>
                {children}
            </Link>
        );
    }

    if (onClick) {
        return (
            // eslint-disable-next-line react/button-has-type
            <button type={type} className={clickableClassName} onClick={onClick}>
                {children}
            </button>
        );
    }

    return <div className={unClickableClassName}>{children}</div>;
}
