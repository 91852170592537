/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSiteActivity, useSiteMembers, useSites } from '../../../queries/sites';
import SiteContent from './SiteContent';

export default function Site() {
    const { sites } = useSites();

    const params = useParams<{ id: string }>();
    const site = sites.data?.find((s) => s.id === params.id);
    const history = useHistory();
    const [lastGallery, setLastGallery] = useState<string | undefined>();
    const [disableLoading, setDisableLoading] = useState<boolean>(false);
    const siteActivity = useSiteActivity(params.id, lastGallery);
    const siteMembers = useSiteMembers(site?.id);

    useEffect(() => {
        if (siteActivity.data && !siteActivity.data?.lastGallery) {
            setDisableLoading(true);
        } else {
            setLastGallery(siteActivity.data?.lastGallery);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteActivity.data?.lastGallery]);

    useEffect(() => {
        if (sites && !site) {
            history.push('/');
        }
    }, [history, site, sites]);

    return (
        <SiteContent
            siteActivity={siteActivity}
            siteMembers={siteMembers.data}
            site={site}
            disableLoading={disableLoading}
        />
    );
}
