import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '../../components';
import { SiteResponse, useGetSiteShare } from '../../queries/sites';

export default function ShareModal({ onClose, site }: { onClose: () => void; site: SiteResponse }) {
    const siteShare = useGetSiteShare(site?.id as string);
    const inputRef = useRef<HTMLInputElement>(null);

    const [loaded, setLoaded] = useState(false);

    const loading = siteShare.loading || !siteShare.executed || !loaded;

    useEffect(() => {
        if (site.id) {
            (async () => {
                await siteShare.execute();
                setLoaded(true);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site.id]);

    function onCopy() {
        if (inputRef.current) {
            /* Select the text field */
            inputRef.current.select();
            inputRef.current.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand('copy');
        }
    }

    function onShare() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const shareContent: any = {
            title: 'SitePhoto',
            text: `View the content of ${site?.name}`,
            url: siteShare.data?.url,
        };
        if (navigator?.share) {
            navigator.share(shareContent);
        }
    }

    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
            {loading ? (
                <Loader />
            ) : (
                <div className="w-full max-w-3xl mx-auto flex-col text-center">
                    <h3>Create Share Link</h3>
                    <p className="text-sm text-gray-400">
                        This link will give the recipient read-only access to this site for 7 days
                    </p>

                    <div className="bg-white bg-opacity-25 p-2 my-6 rounded text-white flex items-center relative">
                        <input
                            readOnly
                            value={siteShare?.data?.url}
                            ref={inputRef}
                            className="h-full w-full bg-transparent pointer-events-none"
                        />
                        <div
                            className="absolute right-0 flex items-center mx-1 space-x-1"
                            style={{ backgroundColor: '#404040' }}
                        >
                            <button onClick={onCopy} type="button" className="p-1 flex">
                                <span className="material-icons">content_copy</span>
                            </button>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            {navigator?.share && (
                                <button onClick={onShare} type="button" className="p-1 flex">
                                    <span className="material-icons">share</span>
                                </button>
                            )}
                        </div>
                    </div>

                    <button
                        type="button"
                        onClick={onClose}
                        className="px-3 py-2 rounded bg-blue-500"
                    >
                        Done
                    </button>
                </div>
            )}
        </div>
    );
}
