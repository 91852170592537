import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Loader } from '../../components';
import logo from '../../assets/images/logo512.png';
import { useResetPassword } from '../../queries/authentication';
import { useQuery } from '../../utils';
import usePasswordValidation from '../../helpers/use-password-validation';

export default function ResetPassword() {
    const [password, setPassword] = useState(process.env.REACT_APP_DEV_PASSWORD || '');
    const resetPassword = useResetPassword();
    const query = useQuery();

    const email = query.email as string;
    const token = query.token as string;

    const [submitted, setSubmitted] = useState(false);

    const [passwordError, validatePassword] = usePasswordValidation(password, !submitted);

    const error = resetPassword.error || passwordError;

    async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setSubmitted(true);
        resetPassword.reset();

        if (validatePassword(password).length > 0) {
            return;
        }

        await resetPassword.execute({ email, password, token });
    }

    useEffect(() => {
        return () => {
            resetPassword.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (resetPassword.success) {
        return (
            <div className="flex flex-col">
                <h1 className="text-4xl text-center text-white mt-12 mb-6">SitePhoto</h1>
                <div className="text-gray-200 p-2 rounded text-center">
                    Your password has been reset successfully.
                    <br />
                    Please proceed to sign in.
                    <br />
                    <Link
                        to="/login"
                        className="mt-4 mb-2 inline-block button bg-primary py-2 px-4 rounded bg-opacity-75 hover:bg-opacity-100"
                    >
                        Sign in
                    </Link>
                </div>
            </div>
        );
    }

    if (!email || !token) {
        return (
            <div className="flex flex-col items-center">
                <h1 className="text-4xl text-center text-white mt-12 mb-6">SitePhoto</h1>
                <div className="bg-red-600 bg-opacity-25 p-2 rounded">
                    Your token has expired or is incorrect
                </div>
            </div>
        );
    }

    return (
        <div className="flex">
            <form
                onSubmit={onFormSubmit}
                className="w-72 max-w-full mx-auto px-2 pt-8 pb-2 text-lg"
            >
                <img src={logo} alt="logo" className="h-32 mx-auto" />
                <h1 className="text-4xl text-center text-white mt-3 mb-6">SitePhoto</h1>
                {error && <div className="text-red-600 my-3 text-center">{error}</div>}
                <div className="my-3 text-center">Please enter your new password</div>
                <Input
                    label="Password"
                    type="password"
                    password
                    value={password}
                    mt-3
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    type="submit"
                    className="mt-4 mb-2 button bg-primary py-2 px-4 rounded bg-opacity-75 hover:bg-opacity-100 flex items-center"
                >
                    Reset password
                    {resetPassword.loading ? (
                        <div
                            className="relative w-8"
                            style={{ transform: 'scale(0.3)', marginTop: '-17%' }}
                        >
                            <Loader />
                        </div>
                    ) : (
                        <span className="material-icons ml-2">arrow_right_alt</span>
                    )}
                </button>
            </form>
        </div>
    );
}
