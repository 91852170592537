import React from 'react';
import clsx from 'clsx';

interface CustomAlertProps {
    message: string;
    onClose: () => void;
}

export default function CustomAlert({ message, onClose }: CustomAlertProps) {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-md w-full flex flex-col items-center">
                <p className="mb-4 text-lg text-center">{message}</p>
                <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 bg-primary text-white rounded hover:bg-red-600 transition-colors duration-150"
                >
                    OK
                </button>
            </div>
        </div>
    );
}
