import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { PhotoUpload } from '.';
import { SiteResponse } from '../queries/sites';

type Image = File & { preview: string };

export default function PhotoUploadButton({
    afterSubmit,
    site,
}: {
    afterSubmit?: () => void;
    site?: SiteResponse;
}) {
    const [images, setImages] = React.useState<Image[] | undefined>(undefined);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setImages(
            acceptedFiles.map((af) =>
                Object.assign(af, {
                    preview: URL.createObjectURL(af),
                })
            )
        );
        setIsOpen(true);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
        accept: 'image/png, image/jpeg',
    });

    function onClose(clearImages: boolean) {
        setIsOpen(false);

        if (clearImages) {
            setImages(undefined);
            if (afterSubmit) {
                afterSubmit();
            }
        } else if (images?.length === 0) {
            setImages(undefined);
        }
    }

    return (
        <>
            {images && (
                <PhotoUpload
                    isOpen={isOpen}
                    images={images}
                    site={site}
                    addImages={(newImages) => setImages([...images, ...newImages])}
                    clearImage={(i) => setImages(images.filter((_, ii) => ii !== i))}
                    onClose={onClose}
                />
            )}
            <div className="fixed bottom-0 right-0 m-4 z-20">
                {images ? (
                    <button
                        type="button"
                        onClick={() => setIsOpen(true)}
                        className="bg-primary h-16 w-16 rounded-full flex items-center justify-center cursor-pointer outline-none shadow-lg"
                    >
                        <span className="material-icons text-3xl">camera_alt</span>
                    </button>
                ) : (
                    <button
                        type="button"
                        {...getRootProps()}
                        className="bg-primary h-16 w-16 rounded-full flex items-center justify-center cursor-pointer outline-none shadow-lg"
                    >
                        <input {...getInputProps()} />
                        <span className="material-icons text-3xl">camera_alt</span>
                    </button>
                )}
            </div>
        </>
    );
}
