/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSharedSite } from '../../../queries/sites';
import { useQuery } from '../../../utils';
import { Loader } from '../../../components';
import SiteContent from './SiteContent';

export default function SharedSite() {
    const params = useParams<{ id: string }>();
    const token = useQuery().token as string;
    const [lastGallery, setLastGallery] = useState<string | undefined>();
    const [disableLoading, setDisableLoading] = useState<boolean>(false);
    const sharedSite = useSharedSite(params.id, token, lastGallery);

    useEffect(() => {
        if (sharedSite.data && !sharedSite.data?.lastGallery) {
            setDisableLoading(true);
        } else {
            setLastGallery(sharedSite.data?.lastGallery);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedSite.data?.lastGallery]);

    if (sharedSite.error || !token) {
        return (
            <div className="flex flex-col items-center">
                <h1 className="text-4xl text-center text-white mt-12 mb-6">SitePhoto</h1>
                <div className="bg-red-600 bg-opacity-25 p-2 rounded">
                    Your token has expired or is incorrect
                </div>
            </div>
        );
    }
    if (sharedSite.loading && !sharedSite.data) {
        return <Loader />;
    }

    return <SiteContent siteActivity={sharedSite} disableLoading={disableLoading} />;
}
