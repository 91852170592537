import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSites } from '../queries/sites';
import { Asset } from '../queries/user';
import useDisableBodyScroll from '../helpers/use-disable-body-scroll';
import ConfirmModal from './ConfirmModal';
import { useUpsertAssetsTags } from '../queries/assets';
import PhotoAndTagsManagement from './PhotoAndTagsManagement';
import { Loader } from '.';

export default function BulkTagsManagement({
    assetsRef,
    assets,
    isOpen,
    onClose,
    defaultImageIndex,
    siteId,
    tags: _tags,
}: {
    assetsRef: Asset[];
    assets: { id: string; imageUrl?: string }[];
    siteId: string;
    isOpen: boolean;
    defaultImageIndex: number;
    tags: string[][] | undefined;
    onClose: () => void;
}) {
    const { sites } = useSites();

    const site = sites.data?.find((s) => s.id === siteId);

    const { enable, disable } = useDisableBodyScroll(true);

    const [tags, setTags] = useState<string[][] | undefined>(_tags);

    const hasChanged = assets.some((asset, i) => {
        const newTags = tags && tags[i];
        const oldTags = _tags && _tags[i];

        return JSON.stringify(newTags) !== JSON.stringify(oldTags);
    });

    const { loading, execute } = useUpsertAssetsTags(
        siteId,
        assets.map((asset, i) => {
            return {
                id: asset.id,
                tags: tags ? tags[i] : [],
            };
        })
    );

    async function upsertAssetsTags() {
        await execute();
        assets
            .map((asset, i) => {
                return {
                    id: asset.id,
                    tags: tags ? tags[i] : [],
                };
            })
            .forEach((updatedAsset) => {
                const toUpdate = assetsRef.find((a) => a.id === updatedAsset.id);
                if (toUpdate) {
                    toUpdate.tags = updatedAsset.tags;
                }
            });
        onClose();
    }

    const imageUrls = assets.map((a) => a.imageUrl as string);

    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        if (isOpen) {
            disable();
        } else {
            enable();
        }
        return () => enable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    React.useEffect(() => {
        if (!imageUrls) {
            setTags(undefined);
        } else if (!tags) {
            setTags(imageUrls.map(() => []));
        }
    }, [imageUrls, tags]);

    const [selectedImage, setSelectedImage] = useState(defaultImageIndex);

    const selectedTags = tags && tags[selectedImage];

    if (!isOpen) return null;

    return (
        <div className="flex fixed inset-0 z-30 bg-base">
            {loading && <Loader page />}
            {confirmModal && (
                <ConfirmModal
                    message="You have pending changes that may be lost, are you sure you want to close?"
                    onCancel={() => setConfirmModal(false)}
                    onExit={() => {
                        setConfirmModal(false);
                        onClose();
                    }}
                />
            )}
            <div className="h-full w-full max-w-3xl mx-auto flex flex-col">
                <div className="flex items-center mx-3 my-2">
                    <button
                        type="button"
                        className="p-3 mx-1 flex items-center"
                        onClick={() => {
                            if (hasChanged) setConfirmModal(true);
                            else onClose();
                        }}
                    >
                        <span className="material-icons">keyboard_backspace</span>
                    </button>
                    <h1 className="text-lg">{site?.name}</h1>
                </div>
                <div className="flex-1 overflow-y-auto">
                    <PhotoAndTagsManagement
                        imageUrls={imageUrls}
                        setSelectedImage={setSelectedImage}
                        selectedImage={selectedImage}
                        site={site}
                        selectedTags={selectedTags}
                        tags={tags}
                        setTags={setTags}
                    />

                    {/* Update button */}
                    <button
                        type="button"
                        className={clsx(
                            'ml-3 mt-3 mb-3 px-3 py-2 rounded bg-blue-500 flex items-center',
                            {
                                'bg-opacity-25': !hasChanged,
                            }
                        )}
                        disabled={!hasChanged}
                        onClick={upsertAssetsTags}
                    >
                        Update tags
                    </button>
                </div>
            </div>
        </div>
    );
}
