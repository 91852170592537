import { useWindowWidth } from '@react-hook/window-size';

type Breakpoint = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const breakpoints: {
    bp: Breakpoint;
    minWidth?: number;
}[] = [
    { bp: 'xl', minWidth: 1280 },
    { bp: 'lg', minWidth: 1024 },
    { bp: 'md', minWidth: 768 },
    { bp: 'sm', minWidth: 640 },
    { bp: 'xs', minWidth: 360 },
    { bp: 'xxs' },
];

export default function useBreakpoint() {
    const windowWidth = useWindowWidth();

    const bp = breakpoints.find((b) => {
        if (!b.minWidth) return true;
        if (windowWidth >= b.minWidth) return true;
        return false;
    });

    return {
        isLarger(breakpoint: Breakpoint) {
            const width = breakpoints.find((b) => b.bp === breakpoint)?.minWidth;
            return width && windowWidth >= width;
        },
        bp: bp?.bp,
    };
}
