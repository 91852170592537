import React from 'react';
import { refresh, isTokenValid } from '../queries/authentication';

/**
 * Sets up the app:  injects token refresh and handling errors.
 */
export default function SetupWrapper({ children }: { children: React.ReactNode }) {
    const [initialized, setInitialized] = React.useState(false);

    React.useEffect(() => {
        if (!isTokenValid()) {
            (async () => {
                await refresh();
                setInitialized(true);
            })();
        } else {
            setInitialized(true);
        }
    }, []);

    if (!initialized) {
        return <></>;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return <>{children}</>;
}
