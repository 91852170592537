import React, { useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useUserPasswordChange } from '../../queries/user';
import { Input, Loader } from '../../components';
import SiteWrapper from '../site/SiteWrapper';
import { wait } from '../../utils';

export default function UpdatePassword() {
    const [form, setForm] = useState({
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
    });

    const userPasswordChange = useUserPasswordChange();

    const valid = (() => {
        if (userPasswordChange.loading) {
            return false;
        }
        return !!(form.currentPassword && form.newPassword === form.newPasswordRepeat);
    })();

    const history = useHistory();
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { goBack } = history as any;

    async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        await userPasswordChange.execute(form);
    }

    async function onClose() {
        await goBack();
        await wait(500);
        userPasswordChange.reset();
    }

    if (userPasswordChange.success) {
        return (
            <SiteWrapper siteName="Profile">
                <div className="w-full max-w-lg mx-auto px-2 pt-2 pb-4">
                    <h3 className="text-xl mt-2 mb-2">Password Updated</h3>
                    <p className="text-gray-400">
                        Your password has been successfully updated.
                        <br />
                        Please use your new password on your next login.
                    </p>
                    <div className="flex mt-4 justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className={clsx(
                                'relative bg-primary py-2 px-4 rounded flex items-center bg-opacity-75 hover:bg-opacity-100'
                            )}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </SiteWrapper>
        );
    }

    return (
        <SiteWrapper siteName="Profile">
            <form onSubmit={onFormSubmit} className="w-full max-w-lg mx-auto px-2 pt-2 pb-4">
                <h3 className="text-xl mt-2 mb-2">Update Details</h3>
                {userPasswordChange.error && (
                    <div className="text-red-600 my-3">{userPasswordChange.error}</div>
                )}
                <Input
                    label="Your current password"
                    mt-3
                    type="password"
                    value={form.currentPassword}
                    onChange={(e) => setForm({ ...form, currentPassword: e.target.value })}
                />
                <Input
                    label="New password"
                    mt-3
                    type="password"
                    value={form.newPassword}
                    onChange={(e) => setForm({ ...form, newPassword: e.target.value })}
                />
                <Input
                    label="Repeat new password"
                    mt-3
                    type="password"
                    value={form.newPasswordRepeat}
                    onChange={(e) => setForm({ ...form, newPasswordRepeat: e.target.value })}
                />
                <div className="flex mt-4 justify-end">
                    <button
                        type="submit"
                        disabled={!valid}
                        className={clsx('relative bg-primary py-2 px-4 rounded flex items-center', {
                            'bg-opacity-25': !valid,
                            'bg-opacity-75 hover:bg-opacity-100': valid,
                        })}
                    >
                        Update
                        {userPasswordChange.loading ? (
                            <div
                                className="relative w-8"
                                style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                            >
                                <Loader />
                            </div>
                        ) : (
                            <span className="material-icons ml-2">arrow_right_alt</span>
                        )}
                    </button>
                </div>
            </form>
        </SiteWrapper>
    );
}
