// used as a boolean when you delete an image to ensure the sitecontents update is triggered

let imageDeleted = false;

export function setImageDeleted(value: boolean) {
    imageDeleted = value;
}

export function getImageDeleted() {
    return imageDeleted;
}
