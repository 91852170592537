import React from 'react';
import clsx from 'clsx';

export default function Select({
    value,
    label,
    onChange,
    disabled,
    options,
    ...props
}: {
    value: string;
    label: string | React.ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    disabled?: boolean;
    options: { value: string; name: string }[];
    [rem: string]: unknown;
}) {
    const className = clsx(props, 'w-full block');

    return (
        <label className={className}>
            <span className="block text-opacity-75 text-white font-thin">{label}</span>
            <select
                className="bg-black bg-opacity-50 p-2 rounded w-full"
                onChange={onChange}
                value={value}
                disabled={disabled}
            >
                <option key="" value="" disabled>
                    -- Select --{' '}
                </option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </label>
    );
}
