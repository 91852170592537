import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSites } from '../../queries/sites';
import Image from '../../components/Image';
import { getImageThumb } from '../../helpers/image';
import useBreakpoints from '../../helpers/use-breakpoints';

function photosLabel(photoCount?: number) {
    if (!photoCount) return 'No Photos';
    if (photoCount === 1) return '1 Photo';
    return `${photoCount} Photos`;
}

export default function Sites({ active }: { active: boolean }) {
    const { sites, recent, archived } = useSites();
    const { isLarger } = useBreakpoints();
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const recentPhotosCount = (() => {
        if (isLarger('md')) {
            return 5;
        }
        if (isLarger('sm')) {
            return 4;
        }
        return 3;
    })();

    React.useEffect(() => {
        if (active) {
            document.documentElement.scrollTop = 0;
        }
    }, [active]);

    const imageSize = `calc((100vw - (100vw - 100%))/${recentPhotosCount})`;

    return (
        <div className="mx-2 py-2 overflow-hidden">
            {recent.data && recent.data?.length > 0 && (
                <div>
                    <h3 className="text-2xl mb-4">Recents</h3>
                    <div className="flex pb-2 -mx-1">
                        {recent.data?.slice(0, recentPhotosCount).map((r) => (
                            <Link
                                to={`/site/${r.id}`}
                                key={r.id}
                                className="m-1"
                                style={{ width: imageSize }}
                            >
                                <div className="square relative">
                                    <div className="absolute inset-0">
                                        <Image
                                            src={
                                                r.icon &&
                                                r.iconExtension &&
                                                getImageThumb(r.id, r.icon, r.iconExtension)
                                            }
                                            size="100%"
                                            alt="img"
                                        />
                                    </div>
                                </div>
                                <div className="mt-1 font-thin">{r.name}</div>
                            </Link>
                        ))}
                    </div>
                </div>
            )}
            {sites.data && (
                <div>
                    <h3 className="text-2xl mt-4 flex justify-between items-center">
                        All Sites
                        <div className="relative ml-auto">
                            <input
                                type="text"
                                placeholder="Search sites by name..."
                                className="border px-2 py-1 rounded focus:outline-none text-sm text-black"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{ width: '150px' }}
                            />
                            <div
                                className="absolute inset-0 bg-white pointer-events-none"
                                style={{ opacity: 0, zIndex: -1 }}
                            />
                        </div>
                    </h3>
                    <div className="-mx-2">
                        {sites.data
                            .filter((site) =>
                                site.name.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((r) => (
                                <Link to={`/site/${r.id}`} key={r.id} className="mx-2 mt-2 flex">
                                    <Image
                                        src={
                                            r.icon &&
                                            r.iconExtension &&
                                            getImageThumb(r.id, r.icon, r.iconExtension)
                                        }
                                        size="80px"
                                        alt="img"
                                    />
                                    <div className="ml-2 flex flex-col justify-center">
                                        <div className="font-thin text-sm">{r.name}</div>
                                        <div className="font-thin text-primary text-xs">
                                            {photosLabel(r.assetCount.IMG)}
                                            <span className="mx-1">|</span> {r.memberCount} Member
                                            {r.memberCount > 1 ? 's' : ''}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                    </div>
                </div>
            )}
            {!archived.data?.length ? null : (
                <div>
                    <h3 className="text-2xl mt-4">Archived</h3>
                    <div className="-mx-2">
                        {archived.data?.map((r) => (
                            <div key={r.id} className="mx-2 mt-2 flex">
                                <Image
                                    src={
                                        r.icon &&
                                        r.iconExtension &&
                                        getImageThumb(r.id, r.icon, r.iconExtension)
                                    }
                                    size="80px"
                                    alt="img"
                                />
                                <div className="ml-2 flex flex-col justify-center">
                                    <div className="font-thin text-sm">{r.name}</div>
                                    <div className="font-thin text-primary text-xs">
                                        {r.assetCount.IMG} Photo{r.assetCount.IMG > 1 ? 's' : ''}{' '}
                                        <span className="mx-1">|</span> {r.memberCount} Member
                                        {r.memberCount > 1 ? 's' : ''}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
