import React from 'react';
import clsx from 'clsx';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { format } from 'date-fns';
import dateFnsFormat from 'date-fns/format';
import Input from './Input';
import { DATE_FORMAT } from '../utils';

const formatDate = (date: Date, formatString: string, local: string): string => {
    return dateFnsFormat(date, DATE_FORMAT);
};

export default function DatePicker({
    value,
    label,
    onChange,
    disabled,
    maxDate,
    ...props
}: {
    value: Date;
    label: string;
    type?: 'text' | 'password' | 'email' | 'number';
    onChange: (event: Date) => void;
    disabled?: boolean;
    maxDate?: Date;
    [rem: string]: unknown;
}) {
    const className = clsx(props, 'w-full block');

    if (disabled) {
        return <Input value={format(value, DATE_FORMAT)} label={label} disabled {...props} />;
    }

    if (maxDate) {
        return (
            <label className={className}>
                <span className="block text-opacity-75 text-white font-thin">{label}</span>
                <DayPickerInput
                    onDayChange={onChange}
                    formatDate={formatDate}
                    dayPickerProps={{
                        disabledDays: {
                            after: maxDate,
                        },
                    }}
                    value={value}
                    format={DATE_FORMAT}
                />
            </label>
        );
    }
    return (
        <label className={className}>
            <span className="block text-opacity-75 text-white font-thin">{label}</span>
            <DayPickerInput
                onDayChange={onChange}
                formatDate={formatDate}
                value={value}
                format={DATE_FORMAT}
            />
        </label>
    );
}
