import clsx from 'clsx';
import React from 'react';
import { Loader } from '../../../components';
import { useResendInvitation } from '../../../queries/sites';

export default function ConfirmResendModal({
    onClose,
    siteId,
    invitationId,
}: {
    onClose: () => void;
    siteId: string;
    invitationId: string;
}) {
    const resendInvitation = useResendInvitation(siteId, invitationId);

    async function onDelete() {
        const [error] = await resendInvitation.execute();
        if (!error) {
            onClose();
        }
    }

    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
            <h3>Are you sure you want to resend the invitation?</h3>
            <div className="flex space-x-4 mt-4">
                <button
                    type="button"
                    onClick={() => onClose()}
                    className="px-3 py-2 rounded m-1 bg-white text-black bg-opacity-75"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={onDelete}
                    disabled={resendInvitation.loading}
                    className={clsx('px-3 py-2 rounded m-1 bg-blue-500 flex items-center', {
                        'bg-opacity-25': resendInvitation.loading,
                        'bg-opacity-75 hover:bg-opacity-100': !resendInvitation.loading,
                    })}
                >
                    Resend
                    {resendInvitation.loading && (
                        <div
                            className="relative w-8"
                            style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                        >
                            <Loader />
                        </div>
                    )}
                </button>
            </div>
        </div>
    );
}
