import { useState } from 'react';

export default function useAsync<Req = void, Res = void>(operation: (req: Req) => Promise<Res>) {
    const [loading, setLoading] = useState(false);

    async function execute(req: Req) {
        setLoading(true);
        try {
            return await operation(req);
        } finally {
            setLoading(false);
        }
    }

    return { loading, execute };
}
