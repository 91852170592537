import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input, Loader } from '../../components';
import logo from '../../assets/images/logo512.png';
import { useForgotPassword } from '../../queries/authentication';

export default function ForgotPassword() {
    const [email, setEmail] = useState(process.env.REACT_APP_DEV_EMAIL || '');
    const forgotPassword = useForgotPassword();

    async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        await forgotPassword.execute({ email });
    }

    useEffect(() => {
        return () => {
            forgotPassword.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (forgotPassword.success || forgotPassword.error) {
        return (
            <div className="flex flex-col items-center">
                <h1 className="text-4xl text-center text-white mt-12 mb-6">SitePhoto</h1>
                <div className="text-gray-200 p-2 rounded text-center">
                    If an account is associated with <strong>{email}</strong>, an email with
                    password reset instructions will be sent shortly.
                    <br />
                    <Link
                        to="/login"
                        className="mt-4 mb-2 inline-block button bg-primary py-2 px-4 rounded bg-opacity-75 hover:bg-opacity-100"
                    >
                        Return to sign in
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="flex">
            <form
                onSubmit={onFormSubmit}
                className="w-72 max-w-full mx-auto px-2 pt-8 pb-2 text-lg"
            >
                <img src={logo} alt="logo" className="h-32 mx-auto" />
                <h1 className="text-4xl text-center text-white mt-3 mb-6">SitePhoto</h1>
                <div className="my-3 text-center">Please enter your email</div>
                <Input
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button
                    type="submit"
                    className="mt-4 mb-2 button bg-primary py-2 px-4 rounded bg-opacity-75 hover:bg-opacity-100 flex items-center"
                >
                    Reset password
                    {forgotPassword.loading ? (
                        <div
                            className="relative w-8"
                            style={{ transform: 'scale(0.3)', marginTop: '-17%' }}
                        >
                            <Loader />
                        </div>
                    ) : (
                        <span className="material-icons ml-2">arrow_right_alt</span>
                    )}
                </button>
            </form>
        </div>
    );
}
