import queryString from 'query-string';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Joins strings into a relative url:  a, b, c => /a/b/c
 * @param urls
 */
export function joinUrl(...urls: string[]): string {
    /**
     * Remove all slashes from the beginning or ends of urls.
     * @param url
     */
    function parseUrl(url: string): string {
        if (url.startsWith('/')) {
            return parseUrl(url.slice(1));
        }
        if (url.endsWith('/')) {
            return parseUrl(url.slice(0, -1));
        }
        return url;
    }

    const parsedUrl = urls.map((url) => parseUrl(url)).join('/');

    // Changes a/b/c to /a/b/c
    return parsedUrl.startsWith('/') ? parsedUrl : `/${parsedUrl}`;
}

export type ServerError = {
    entity?: string;
    gatewayMessage?: string;
    identifier?: string;
    message: string;
    responseData?: string;
    stackTrace?: string;
    statusCode: number;
    type?: string;
    validationErrors?: string;
};

export function parseError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    defaultMessage = 'An unknown error has occured'
): string {
    if (error?.response?.data) {
        const serverError: ServerError = error.response.data;
        return serverError.message;
    }
    return defaultMessage;
}

export function wait(n: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, n);
    });
}

export function useQuery() {
    const location = useLocation();

    return queryString.parse(location.search);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getQueryString(query: any) {
    const string = queryString.stringify(query);
    return string ? `?${string}` : '';
}

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export function validatePassword(password: string): string[] {
    const validations: string[] = [];

    if (password.length < 8) {
        validations.push('Contain at least 8 characters');
    }
    if (!/[a-z]/.test(password)) {
        validations.push('Contain at least 1 lowercase character');
    }
    if (!/[A-Z]/.test(password)) {
        validations.push('Contain at least 1 uppercase character');
    }
    if (!/[@$!%*?&#^(){}[\]/\\,.;:'|+=<>~`]/.test(password)) {
        validations.push('Contain at least 1 special character');
    }
    return validations;
}

export function useIsMounted() {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback(() => isMounted.current, []);
}
