import { useEffect } from 'react';
import { useLogin } from '../queries/authentication';
import { logout } from '../store';

export function useLogout() {
    const auth = useLogin();

    useEffect(() => {
        if (auth.data) {
            logout();
        }
    }, [auth.data]);

    return !auth?.data;
}
