import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import * as R from 'ramda';
import { Link, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { SiteMembers, SiteResponse, useSiteActivity } from '../../../queries/sites';
import ImageGrid from '../../../components/ImageGrid';
import PhotoUploadButton from '../../../components/PhotoUploadButton';
import { getImageThumb } from '../../../helpers/image';
import SiteWrapper from '../SiteWrapper';
import Image from '../../../components/Image';
import TagHandler from '../../../components/TagHandler';
import Button from '../../../components/Button';
import ShareModal from '../ShareModal';
import useAddressFromLocation from '../UpsertSite/map-helpers';
import { getImageDeleted, setImageDeleted } from '../../../helpers/globalstate';

function CollapsableArea({ heading, children }: { heading: string; children: React.ReactNode }) {
    const [isCollapsed, setCollapsed] = useState(true);

    return (
        <div className="mt-4">
            <button
                type="button"
                className="text-lg flex items-center px-2 border border-gray-400 hover:border-gray-200 inline-block px-3 rounded-lg"
                onClick={() => setCollapsed(!isCollapsed)}
            >
                {heading}
                <span className="material-icons mr-2 text-xl">
                    {isCollapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
                </span>
            </button>
            {!isCollapsed && <div>{children}</div>}
        </div>
    );
}

type SiteActivity = ReturnType<typeof useSiteActivity>;

export default function SiteContent({
    siteActivity,
    siteMembers,
    disableLoading,
    site,
}: {
    siteActivity: SiteActivity;
    disableLoading?: boolean;
    siteMembers?: SiteMembers;
    site?: SiteResponse;
}) {
    const history = useHistory();
    const location = useLocation();
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [isShareOpen, setShareOpen] = useState(false);

    function openShare() {
        setShareOpen(true);
    }

    useEffect(() => {
        // Check if an image was deleted upon returning to this page
        if (getImageDeleted()) {
            siteActivity.execute(true); // Re-fetch the data
            setImageDeleted(false); // Reset the delete state after handling
        }
    }, [location, siteActivity]); // Trigger useEffect when the location changes

    function toggleMember(memberId: string) {
        setSelectedMembers(
            R.includes(memberId, selectedMembers)
                ? R.without([memberId], selectedMembers)
                : R.append(memberId, selectedMembers)
        );
    }

    const address = useAddressFromLocation(
        site ? { lat: site.latitude, lng: site.longitude } : undefined
    );

    const sections = React.useMemo(() => {
        if (selectedTags.length === 0 && selectedMembers.length === 0)
            return siteActivity?.data?.sections;
        return (
            siteActivity?.data?.sections
                .map((s) => ({
                    ...s,
                    galleries: s.galleries
                        .map((g) => ({
                            ...g,
                            assets: g.assets
                                .map((a) => ({
                                    ...a,
                                    tags: selectedTags.length
                                        ? R.intersection(a.tags || [], selectedTags)
                                        : a.tags,
                                }))
                                /** filter out all empty tags + members */
                                .filter((a) => {
                                    const areTagsValid =
                                        !selectedTags.length || (a.tags || [])?.length > 0;

                                    const areMembersValid =
                                        !selectedMembers.length ||
                                        selectedMembers.includes(a.createdBy?.id);

                                    return areTagsValid && areMembersValid;
                                }),
                        }))
                        /** filter out empty galleries */
                        .filter((a) => a.assets.length > 0),
                }))
                /** filter out sections */
                .filter((s) => s.galleries.length > 0)
        );
    }, [selectedMembers, selectedTags, siteActivity.data?.sections]);

    /**
     * SharedSite does not have access to site, so siteName needs to come from "sections"
     */
    const siteName = (() => {
        if (site) return site.name;
        if (!sections) return undefined;
        // eslint-disable-next-line no-restricted-syntax
        for (const section of sections) {
            const galleryWithSiteName = section.galleries.find((g) => g.siteName);
            if (galleryWithSiteName?.siteName) return galleryWithSiteName?.siteName;
        }
        return undefined;
    })();

    return (
        <SiteWrapper
            siteName={siteName}
            onBack={() => history.push('/?tab=sites')}
            disableBack={!site}
            rightLink={
                site?.isAdmin && (
                    <Link
                        to={`/site/${site?.id}/edit`}
                        className="p-3 mr-1 flex items-center ml-auto"
                    >
                        <span className="material-icons">create</span>
                    </Link>
                )
            }
        >
            {isShareOpen && (
                <ShareModal site={site as SiteResponse} onClose={() => setShareOpen(false)} />
            )}

            {site && (
                <PhotoUploadButton afterSubmit={() => siteActivity.execute(true)} site={site} />
            )}
            {site && (
                <div className="p-2 mt-1">
                    <div className="flex">
                        {site.icon && site.iconExtension ? (
                            <Image
                                src={getImageThumb(site.id, site.icon, site.iconExtension)}
                                size="160px"
                                alt="img"
                            />
                        ) : (
                            <div
                                className="h-24 w-24 flex items-center justify-center"
                                style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                            >
                                <span className="material-icons text-5xl text-gray-500 opacity-25">
                                    insert_photo
                                </span>
                            </div>
                        )}
                        <div className="flex justify-start space-y-3 text-gray-200 items-start flex-col ml-2 flex-1">
                            <div className="text-sm -mt-1">
                                {address
                                    ?.split(',')
                                    .map((s) => s.trim())
                                    .slice(0, 3)
                                    .map((s, i) => (
                                        <div key={i}>{s}</div>
                                    ))}
                            </div>
                            <p className="text-sm text-gray-400">
                                {format(new Date(site.startDate), 'd MMM yyyy')} -{' '}
                                {format(new Date(site.completionDate), 'd MMM yyyy')}
                            </p>
                            <div className="mt-1 flex flex-col space-y-2 w-full md:w-auto">
                                <Button to={`/site/${site.id}/members`} text-center>
                                    {site.memberCount} Member
                                    {site.memberCount > 1 ? 's' : ''}
                                </Button>
                                <div className="flex space-x-2">
                                    <Link
                                        to={`/site/${site.id}/details`}
                                        className="text-center flex-1 outline-none bg-none py-0 border border-gray-400 hover:border-gray-200 inline-block px-3 rounded-lg"
                                    >
                                        View
                                    </Link>
                                    <Button flex-1 onClick={openShare}>
                                        Share
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CollapsableArea heading="Tags">
                        {site?.tags && (
                            <TagHandler
                                tags={site.tags.sort((a, b) => a.localeCompare(b))}
                                selectedTags={selectedTags}
                                disableEdit
                                setSelectedTags={setSelectedTags}
                            />
                        )}
                    </CollapsableArea>
                    <CollapsableArea heading="Members">
                        {siteMembers && (
                            <div className="flex mt-2 flex-wrap">
                                {siteMembers.members.map((sm) => {
                                    const isSelected = selectedMembers.includes(sm.id);
                                    return (
                                        <button
                                            key={sm.id}
                                            type="button"
                                            onClick={() => toggleMember(sm.id)}
                                            className={clsx(`px-3 py-2 rounded m-1 relative`, {
                                                'bg-primary': isSelected,
                                                'bg-white bg-opacity-25': !isSelected,
                                            })}
                                        >
                                            {sm.firstName} {sm.lastName}
                                        </button>
                                    );
                                })}
                            </div>
                        )}
                    </CollapsableArea>
                </div>
            )}
            <ImageGrid
                sections={sections}
                disableLinks
                disableLoading={disableLoading}
                disableEdit={!site}
                emptyState={<div className="text-center mt-4 text-grey-700">No photos</div>}
                onBottomScroll={() => {
                    siteActivity.execute();
                }}
                loading={siteActivity.loading}
            />
        </SiteWrapper>
    );
}
