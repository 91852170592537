import React, { useState } from 'react';
import Loader from './Loader';
import useBreakpoints from '../helpers/use-breakpoints';
import { wait } from '../utils';
import useAsync from '../helpers/use-async';

export default function Image({
    size,
    onClick,
    src,
    padded,
    smallIcon,
    ...props
}: {
    size: string;
    src?: string;
    smallIcon?: boolean;
    padded?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    [rem: string]: unknown;
}) {
    const [failed, setFailed] = useState(0);

    const { loading, execute: onError } = useAsync(
        async (error: React.SyntheticEvent<HTMLImageElement, Event>) => {
            error.stopPropagation();
            setFailed((f) => f + 1);
            await wait(5000);
        }
    );

    const { isLarger } = useBreakpoints();

    const imageSpacing = (() => {
        if (!padded) {
            return 0;
        }
        if (isLarger('md')) {
            return '5px';
        }
        if (isLarger('sm')) {
            return '3px';
        }
        if (isLarger('xs')) {
            return '2px';
        }
        return '1px';
    })();

    if (!src) {
        return (
            <div
                style={{
                    minWidth: size,
                    maxWidth: size,
                    height: size || 'auto',
                    padding: imageSpacing,
                }}
            >
                <div
                    className="h-full w-full flex items-center justify-center"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                >
                    <span
                        className={`material-icons ${
                            smallIcon ? 'text-2xl' : 'text-5xl'
                        } text-gray-500 opacity-25`}
                    >
                        insert_photo
                    </span>
                </div>
            </div>
        );
    }

    if (failed > 3) {
        return (
            <div
                className="square relative"
                style={{
                    minWidth: size,
                    maxWidth: size,
                    height: size || 'auto',
                    width: '100%',
                }}
            >
                <div
                    className="flex items-center justify-center text-center border border-gray-700 absolute"
                    style={{
                        left: imageSpacing,
                        right: imageSpacing,
                        top: imageSpacing,
                        bottom: imageSpacing,
                    }}
                >
                    Image failed to load
                </div>
            </div>
        );
    }
    if (loading) {
        return (
            <div
                className="flex items-center justify-center relative square"
                style={{
                    minWidth: size,
                    maxWidth: size,
                    height: size,
                    padding: imageSpacing,
                }}
            >
                <Loader />
            </div>
        );
    }
    try {
        return (
            <button
                onClick={onClick}
                type="button"
                className="relative square"
                style={{
                    minWidth: size,
                    maxWidth: size,
                    height: size,
                }}
            >
                <div
                    className="absolute inset-0 z-0 bg-white"
                    style={{
                        opacity: 0.1,
                        margin: imageSpacing,
                    }}
                />
                <img
                    className="absolute inset-0 w-full"
                    style={{ padding: imageSpacing }}
                    onError={onError}
                    src={src}
                    {...props}
                    alt="img"
                />
            </button>
        );
    } catch (err) {
        return null;
    }
}
