import React from 'react';
import { Input } from '../../../components';
import DatePicker from '../../../components/DatePicker';
import Select from '../../../components/Select';
import { useSiteTypes } from '../../../queries/sites';
import { SetFormField, FormData } from './UpsertSite';

export function DetailsStep({
    setFormField,
    formData,
}: {
    formData: FormData;
    setFormField: SetFormField;
}) {
    const siteTypes = useSiteTypes();

    return (
        <>
            <h3 className="text-lg mt-3 -mb-1">Details</h3>
            <Input
                label="Name"
                mt-3
                value={formData.name}
                onChange={(e) => setFormField('name')(e.target.value)}
            />
            <Input
                label="Description"
                value={formData.description}
                mt-3
                onChange={(e) => setFormField('description')(e.target.value)}
            />
            <Select
                label="Site type"
                mt-3
                options={
                    siteTypes.data?.map((st) => ({
                        name: st.name,
                        value: st.code,
                    })) || []
                }
                value={formData.typeCode}
                onChange={(e) => setFormField('typeCode')(e.target.value)}
            />
            <DatePicker
                onChange={setFormField('startDate')}
                value={formData.startDate}
                mt-3
                label="Start date"
            />
            <DatePicker
                onChange={setFormField('completionDate')}
                value={formData.completionDate}
                mt-3
                label="Completion date"
            />
            <Input
                label="Scope of works"
                value={formData.scopeOfWorks}
                mt-3
                onChange={(e) => setFormField('scopeOfWorks')(e.target.value)}
            />
        </>
    );
}
