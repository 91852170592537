import * as React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import routes from '../routes';
import layouts from '../layout';

export default function LayoutWrapper({ children }: { children: React.ReactNode }): JSX.Element {
    const location = useLocation();
    const route = routes.find((r) => {
        return !!matchPath(location.pathname, { path: r.path, exact: true });
    });
    const Layout = route?.layout && layouts[route.layout];
    const wrappedChildren = Layout ? <Layout>{children}</Layout> : children;
    return <>{wrappedChildren}</>;
}
