import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Map, GoogleAPI, Marker } from 'google-maps-react';
import { format } from 'date-fns';
import Image from '../../components/Image';
import SiteWrapper from './SiteWrapper';
import { SiteResponse, useSites, useSiteTypes } from '../../queries/sites';

import { getImageThumb } from '../../helpers/image';
import Button from '../../components/Button';
import useAddressFromLocation from './UpsertSite/map-helpers';

function SiteDetails({ google, site }: { google: GoogleAPI; site: SiteResponse }) {
    const siteLocation = site ? { lat: site.latitude, lng: site.longitude } : undefined;

    const address = useAddressFromLocation(siteLocation);
    const siteTypes = useSiteTypes();

    const map = (
        <div className="mb-4">
            <div className="text-lg mt-3 mb-2">Location</div>
            <div className="flex">
                <Map
                    google={google}
                    zoom={10}
                    draggable={!site}
                    initialCenter={
                        siteLocation || {
                            lat: Number.parseInt(process.env.REACT_APP_DEFAULT_LAT as string, 10),
                            lng: Number.parseInt(process.env.REACT_APP_DEFAULT_LNG as string, 10),
                        }
                    }
                    containerStyle={{
                        position: 'relative',
                        width: '100%',
                        height: '240px',
                    }}
                >
                    {siteLocation && <Marker position={siteLocation} title="Your site" />}
                </Map>
            </div>
        </div>
    );

    return (
        <SiteWrapper siteName={site?.name}>
            <div className="p-2">
                <div className="flex items-center">
                    {site.icon && site.iconExtension ? (
                        <Image
                            src={getImageThumb(site.id, site.icon, site.iconExtension)}
                            size="100px"
                            alt="img"
                        />
                    ) : (
                        <div className="bg-white bg-opacity-25 w-24 h-24" />
                    )}
                    <div className="flex flex-col justify-between ml-3">
                        <div>
                            <div className="text-2xl">{site.name}</div>
                            <div className="text-gray-400 text-sm">{site.description}</div>
                        </div>
                        <div className="flex items-center space-x-2 mt-2">
                            <Button to={`/site/${site.id}/members`}>
                                {site.memberCount} Member{site.memberCount === 1 ? '' : 's'}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col space-y-1 mt-4">
                    <div className="flex">
                        <span className="w-32 text-gray-400">Owner</span>
                        <div>
                            {site.ownedBy?.firstName} {site.ownedBy?.lastName}
                        </div>
                    </div>
                    <div className="flex">
                        <span className="w-32 text-gray-400">Type</span>
                        <div>
                            {siteTypes.data?.find((st) => st.code === site.typeCode)?.name ||
                                site.typeCode}
                        </div>
                    </div>
                    <div className="flex">
                        <span className="w-32 text-gray-400">Scope of Works</span>
                        <div>{site.scopeOfWorks}</div>
                    </div>
                    <div className="flex">
                        <span className="w-32 text-gray-400">Start Date</span>
                        <div>{format(new Date(site.startDate), 'd MMM yyyy')}</div>
                    </div>
                    <div className="flex">
                        <span className="w-32 text-gray-400">Completion Date</span>
                        <div>{format(new Date(site.completionDate), 'd MMM yyyy')}</div>
                    </div>
                    <div className="flex">
                        <span className="w-32 text-gray-400">Address</span>
                        <div>
                            {address
                                ?.split(',')
                                .map((s) => s.trim())
                                .slice(0, 3)
                                .map((s, i) => (
                                    <div key={i}>{s}</div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="mx-2 mb-6 pb-6">{map}</div>
            </div>
        </SiteWrapper>
    );
}

export default function SiteDetailsContainer({ google }: { google: GoogleAPI }) {
    const params = useParams<{ id: string }>();
    const { id: siteId } = params;
    const { sites } = useSites();
    const history = useHistory();
    const site = sites.data?.find((s) => s.id === siteId);

    useEffect(() => {
        if (sites && !site && siteId) {
            history.push('/');
        }
    }, [history, site, siteId, sites]);

    if (!site) return null;

    return <SiteDetails site={site} google={google} />;
}
