import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }: { children: React.ReactNode }) => {
    const ref = useRef<HTMLDivElement>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const mount = document.getElementById('portal-root') as HTMLElement;
        ref.current = document.createElement('div');

        mount.appendChild(ref.current);
        setLoaded(true);
        return () => {
            if (ref.current) {
                mount.removeChild(ref.current);
            }
        };
    }, []);

    if (!loaded) return null;

    return createPortal(children, ref.current as HTMLDivElement);
};

export default Portal;
