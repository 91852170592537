import { useCallback, useEffect, useRef, useState } from 'react';

export default function useMouseWheelZoom(
    callback: (zoomIn: boolean) => void,
    exitFullScreenCallback: () => void
) {
    const [elem, setElem] = useState<HTMLDivElement | null>(null);
    const timerRef = useRef<number | null>(null);
    const inputCallbackRef = useRef<(zoomIn: boolean) => void>(callback);
    const lastTouchDistanceRef = useRef<number | null>(null);
    const zoomLevelRef = useRef<number>(1); // Track zoom level

    const callbackRef = useCallback(
        (node: HTMLDivElement | null) => {
            setElem(node);
            if (node) {
                inputCallbackRef.current = callback;
            }
        },
        [callback]
    );

    useEffect(() => {
        function handleWheel(event: WheelEvent) {
            const zoomIn = event.deltaY < 0; // Negative deltaY means scroll up (zoom in)
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            if (inputCallbackRef.current) {
                inputCallbackRef.current(zoomIn);
            }

            // Update zoom level
            zoomLevelRef.current += zoomIn ? 0.1 : -0.1;
            if (zoomLevelRef.current < 1) {
                zoomLevelRef.current = 1; // Prevent zoom level from going below 1
                exitFullScreenCallback();
            }

            timerRef.current = window.setTimeout(() => {
                timerRef.current = null;
            }, 200);
        }

        function handleTouchMove(event: TouchEvent) {
            if (event.touches.length === 2) {
                const touch1 = event.touches[0];
                const touch2 = event.touches[1];
                const touchDistance = Math.sqrt(
                    (touch1.clientX - touch2.clientX) ** 2 + (touch1.clientY - touch2.clientY) ** 2
                );

                if (lastTouchDistanceRef.current !== null) {
                    const zoomOut = touchDistance > lastTouchDistanceRef.current; // Determine zoom out by checking if touch distance is greater than the last recorded distance
                    if (timerRef.current) {
                        clearTimeout(timerRef.current);
                        timerRef.current = null;
                    }
                    if (inputCallbackRef.current) {
                        inputCallbackRef.current(!zoomOut); // Invert the zoom direction here for touch events
                    }

                    // Update zoom level
                    zoomLevelRef.current += zoomOut ? -0.1 : 0.1;
                    if (zoomLevelRef.current < 1) {
                        zoomLevelRef.current = 1; // Prevent zoom level from going below 1
                        exitFullScreenCallback();
                    }

                    timerRef.current = window.setTimeout(() => {
                        timerRef.current = null;
                    }, 200);
                }

                lastTouchDistanceRef.current = touchDistance;
            }
        }

        function handleTouchEnd() {
            lastTouchDistanceRef.current = null;
        }

        if (elem) {
            elem.addEventListener('wheel', handleWheel);
            elem.addEventListener('touchmove', handleTouchMove);
            elem.addEventListener('touchend', handleTouchEnd);
        }

        return () => {
            if (elem) {
                elem.removeEventListener('wheel', handleWheel);
                elem.removeEventListener('touchmove', handleTouchMove);
                elem.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [elem, exitFullScreenCallback]);

    return callbackRef;
}
