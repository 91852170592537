/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { getImageOriginal } from '../../helpers/image';
import { useFavouriteAsset } from '../../queries/assets';
import { Asset } from '../../queries/user';
import { useSites } from '../../queries/sites';
import Loader from '../Loader';

export default function PopupMenu({
    onClose,
    asset,
    siteId,
    canDelete,
    openDeleteModal,
    disableEdit,
}: {
    onClose: () => void;
    openDeleteModal: () => void;
    asset: Asset;
    siteId: string;
    canDelete: boolean;
    disableEdit?: boolean;
}) {
    const favourite = useFavouriteAsset(siteId, asset?.id as string);
    const { sites } = useSites();
    const site = sites.data?.find((s) => s.id === siteId);
    const [isLoading, setIsLoading] = useState(false);

    const isFavourited = (() => {
        if (favourite.local !== undefined) {
            return favourite.local;
        }
        return !!asset.favourite;
    })();

    const handleDownload = async () => {
        setIsLoading(true); // Show loading screen
        try {
            const response = await fetch(getImageOriginal(siteId, asset.id, asset.extension));
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${site?.name}${asset.extension}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href); // Clean up after download
        } catch (error) {
            console.error('Download failed:', error);
        } finally {
            setIsLoading(false); // Hide loading screen after download completes
        }
    };

    return (
        <div className="fixed h-screen w-screen inset-0 z-50" onClick={onClose}>
            {isLoading && <Loader page />} {/* Conditionally render the Loader */}
            <div
                className="absolute top-0 right-0 m-1 p-1 bg-gray-800 rounded"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <ul>
                    {[
                        {
                            icon: 'get_app',
                            name: 'Download',
                            onClick: async () => {
                                await handleDownload(); // Handle download with loading
                                onClose(); // Close the menu after download
                            },
                            visible: true,
                        },
                        {
                            icon: isFavourited ? 'star' : 'star_outline',
                            onClick: () => favourite.post(!isFavourited),
                            visible: !disableEdit,
                            name: isFavourited ? 'Unfavourite' : 'Favourite',
                        },
                        {
                            icon: 'delete',
                            name: 'Delete',
                            onClick: openDeleteModal,
                            visible: !!canDelete,
                        },
                    ]
                        .filter((l) => l?.visible)
                        .map((l) => (
                            <li key={l.icon}>
                                <button
                                    type="button"
                                    className="flex items-center py-3 px-4 hover:bg-gray-700 w-full rounded"
                                    onClick={() => {
                                        if (l.onClick) l.onClick();
                                    }}
                                >
                                    <i className="material-icons text-md mr-3">{l.icon}</i>
                                    {l.name}
                                </button>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}
