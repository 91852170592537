export function getImageOriginal(siteId: string, id: string, fileExtension: string) {
    return `${process.env.REACT_APP_ASSET_CDN}/${siteId}/img/original/${id}${fileExtension}`;
}

export function getImageUrl(siteId: string, id: string, fileExtension: string) {
    return `${process.env.REACT_APP_ASSET_CDN}/${siteId}/img/resized/${id}${fileExtension}`;
}

export function getImageThumb(siteId: string, id: string, fileExtension: string) {
    return `${process.env.REACT_APP_ASSET_CDN}/${siteId}/img/resized/thumb-${id}${fileExtension}`;
}
