import { useEffect } from 'react';
import { domain } from '../../store';
import { Member, SiteMembers, useSites } from '../../queries/sites';

export function useAllMembers() {
    const { sites } = useSites();
    const allSiteMembersPromises = sites?.data?.map((site) =>
        domain.useGet<SiteMembers>(`/sites/${site.id}/members`)
    );

    const allMembers = allSiteMembersPromises?.flatMap(
        (sitePromise) => sitePromise.data?.members ?? []
    );
    const uniqueMembersMap = new Map<string, Member>();
    allMembers?.forEach((member) => {
        uniqueMembersMap.set(member.id, member);
    });

    // Convert Map values back to array
    const uniqueMembers = Array.from(uniqueMembersMap.values());

    return uniqueMembers;
}
