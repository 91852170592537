/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import Image from '../../../components/Image';
import { getImageThumb } from '../../../helpers/image';
import { useSiteMembers, useSites } from '../../../queries/sites';
import { EMPTY_GUID } from '../../../utils';
import SiteWrapper from '../SiteWrapper';
import ConfirmResendModal from './ConfirmResendModal';

export default function SiteMembers() {
    const { sites } = useSites();
    const [resendInvitationId, setResendInvitationId] = useState<undefined | string>(undefined);

    const params = useParams<{ id: string }>();
    const site = sites.data?.find((s) => s.id === params.id);
    const history = useHistory();
    const siteMembers = useSiteMembers(site?.id);

    useEffect(() => {
        if (sites && !site) {
            history.push('/');
        }
    }, [history, site, sites]);

    useEffect(() => {
        siteMembers.execute();
    }, []);

    if (!site) return null;

    return (
        <SiteWrapper siteName={site?.name} onBack={() => history.push(`/site/${site?.id}`)}>
            {resendInvitationId && (
                <ConfirmResendModal
                    siteId={site.id}
                    invitationId={resendInvitationId}
                    onClose={() => setResendInvitationId(undefined)}
                />
            )}
            {site?.isAdmin && (
                <Link
                    to={`/site/${site?.id}/members/add`}
                    className="fixed bottom-0 right-0 m-4 z-20"
                >
                    <button
                        type="button"
                        className="bg-blue-500 h-16 w-16 rounded-full flex items-center justify-center cursor-pointer outline-none shadow-lg"
                    >
                        <span className="material-icons text-3xl">add</span>
                    </button>
                </Link>
            )}
            {site && siteMembers?.data && (
                <div className="p-2 mt-1 flex flex-col">
                    {siteMembers.data.pendingInvitations?.length > 0 && (
                        <div className="mb-2">
                            <div className="mb-1">Pending Invitations</div>
                            <div className="-mx-2">
                                {siteMembers.data.pendingInvitations.map((m) => (
                                    <div key={m.id} className="flex items-center p-2">
                                        <Image size="48px" alt="img" smallIcon />
                                        <div className="flex flex-col content-center ml-2">
                                            <div className="text-primary">
                                                {m.firstName} {m.lastName}
                                            </div>
                                            <div className="text-sm text-gray-300">
                                                {m.email.toLowerCase()}
                                            </div>
                                        </div>
                                        {site?.isAdmin && (
                                            <Button
                                                ml-auto
                                                onClick={() => setResendInvitationId(m.id)}
                                            >
                                                Resend Invite
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="mb-1">Members</div>
                    <div className="-mx-2">
                        {siteMembers.data.members.map((m) => (
                            <Link
                                to={`/site/${site?.id}/members/${m.id}`}
                                key={m.id}
                                className="flex items-center p-2 hover:bg-opacity-50 hover:bg-base"
                            >
                                <Image
                                    src={
                                        m?.profilePicExtension &&
                                        m?.profilePicId &&
                                        getImageThumb(
                                            EMPTY_GUID,
                                            m?.profilePicId,
                                            m?.profilePicExtension
                                        )
                                    }
                                    smallIcon
                                    size="48px"
                                    alt="img"
                                />
                                <div className="flex flex-col content-center ml-2">
                                    <div className="text-primary">
                                        {m.firstName} {m.lastName}{' '}
                                        {(() => {
                                            const isOwner = site.ownedBy?.id === m.id;
                                            if (isOwner) return '(Owner)';
                                            return m.isAdmin ? '(Admin)' : '';
                                        })()}
                                    </div>
                                    <div
                                        className="text-sm text-gray-300"
                                        style={{ textTransform: 'none', textDecoration: 'none' }}
                                    >
                                        {m.email.toLowerCase()}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </SiteWrapper>
    );
}
