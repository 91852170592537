import React from 'react';
import clsx from 'clsx';
import Loader from '../Loader';

export default function MultiSelectDeleteModal({
    onCancel,
    onDelete,
    loading,
    confirmText = 'Are you sure you want to delete these selected photos?',
    cancelText = 'Cancel',
    deleteText = 'Delete',
}: {
    onCancel: () => void;
    onDelete: () => void;
    loading?: boolean;
    confirmText?: string; // Optional prop for custom confirmation text
    cancelText?: string; // Optional prop for custom cancel button text
    deleteText?: string; // Optional prop for custom delete button text
}) {
    return (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
            <h3>{confirmText}</h3>
            <div className="flex space-x-4 mt-4">
                <button
                    type="button"
                    onClick={onCancel}
                    className="px-3 py-2 rounded m-1 bg-white text-black bg-opacity-75"
                >
                    {cancelText}
                </button>
                <button
                    type="button"
                    onClick={onDelete}
                    disabled={loading}
                    className={clsx('px-3 py-2 rounded m-1 bg-red-500 flex items-center', {
                        'bg-opacity-25': loading,
                        'bg-opacity-75 hover:bg-opacity-100': !loading,
                    })}
                >
                    {deleteText}
                    {loading && (
                        <div
                            className="relative w-8"
                            style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                        >
                            <Loader />
                        </div>
                    )}
                </button>
            </div>
        </div>
    );
}
