import clsx from 'clsx';
import React from 'react';
import { ImageInput } from '../../../components';
import TagHandler from '../../../components/TagHandler';
import { getImageThumb } from '../../../helpers/image';
import { useUpsertSiteTags } from '../../../queries/assets';
import { SiteResponse, useSetSiteIcon } from '../../../queries/sites';
import { SetFormField, FormData } from './UpsertSite';

export default function ImageAndTagsStep({
    setFormField,
    formData,
    site,
}: {
    formData: FormData;
    site: SiteResponse | undefined;
    setFormField: SetFormField;
}) {
    const setSiteIcon = useSetSiteIcon();

    const { execute: upsertSiteTags } = useUpsertSiteTags();

    async function updateImage() {
        if (site) {
            setSiteIcon.execute(site.id, formData.icon as File);
            setFormField('icon')(undefined);
        }
    }

    async function onUpsertTags(newSiteTags: string[]) {
        if (site) {
            await upsertSiteTags({
                siteId: site.id,
                tags: newSiteTags,
            });
        }
    }

    const image = (
        <div className="flex flex-col">
            <h3 className="text-lg mt-3 -mb-1">Upload an image</h3>
            <div className="flex">
                <ImageInput
                    value={formData.icon}
                    onChange={setFormField('icon')}
                    mt-3
                    defaultImage={
                        site &&
                        site.icon &&
                        site.iconExtension &&
                        getImageThumb(site.id, site.icon, site.iconExtension)
                    }
                />
                <div className="text-gray-300 ml-3 flex flex-col justify-evenly items-start mt-10 mb-4">
                    <span className="text-sm">
                        Help other members identify your site easily by adding a logo or image to
                        the site
                    </span>
                    {site && (
                        <button
                            className={clsx('px-3 py-2 mt-3 rounded bg-primary flex items-center', {
                                'bg-opacity-25 cursor-default': !formData.icon,
                            })}
                            type="button"
                            onClick={updateImage}
                            disabled={!formData.icon}
                        >
                            Update icon
                        </button>
                    )}
                </div>
            </div>
        </div>
    );

    const tags = (
        <>
            <div className="text-lg mt-3 mb-1">{site ? 'Update Tags' : 'Create Tags'}</div>
            <div className="text-sm text-gray-300">
                Tags are used to group your photos. Only administrators can create or remove tags.
            </div>
            <TagHandler
                tags={formData.tags}
                setTags={setFormField('tags')}
                selectedTags={formData.selectedTags}
                setSelectedTags={setFormField('selectedTags')}
                onTagAdd={site && onUpsertTags}
                onTagDelete={site && onUpsertTags}
            />
        </>
    );

    return (
        <>
            {image}
            {tags}
        </>
    );
}
