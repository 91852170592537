import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { GoogleAPI } from 'google-maps-react';
import clsx from 'clsx';
import SiteWrapper from '../SiteWrapper';
import { Loader } from '../../../components';
import {
    SiteResponse,
    useCreateSite,
    useSetSiteIcon,
    useSites,
    useUpdateSite,
    useUpdateSiteLocation,
} from '../../../queries/sites';
import { parseError } from '../../../utils';
import { useUpsertSiteTags } from '../../../queries/assets';
import MapStep from './MapStep';
import { DetailsStep } from './DetailsStep';
import ImageAndTagsStep from './ImageAndTagsStep';

export type FormData = {
    marker: { lat: number; lng: number } | undefined;
    position: GeolocationPosition | null | undefined;
    errorMessage: string;
    name: string;
    createStep: number | undefined;
    icon: File | undefined;
    description: string;
    scopeOfWorks: string;
    startDate: Date;
    completionDate: Date;
    tags: string[];
    selectedTags: string[];
    typeCode: string;
};

export type SetFormField = <T extends keyof FormData>(field: T) => (value: FormData[T]) => void;

function UpsertSite({ google, site }: { google: GoogleAPI; site: SiteResponse | undefined }) {
    const history = useHistory();

    const siteLocation = site ? { lat: site.latitude, lng: site.longitude } : undefined;

    const [formData, setFormData] = useState<FormData>({
        marker: siteLocation,
        position: undefined,
        errorMessage: '',
        name: '',
        createStep: site ? undefined : 0,
        icon: undefined,
        description: '',
        scopeOfWorks: '',
        startDate: new Date(),
        completionDate: new Date(),
        selectedTags: [],
        tags: [],
        typeCode: '',
    });

    const setFormField: SetFormField = useCallback(
        <T extends keyof FormData>(field: T) =>
            (value: FormData[T]) =>
                setFormData((oldFormData) => ({ ...oldFormData, [field]: value })),
        []
    );

    /**
     * On load, get current location.  (Used if site's location not yet been defined)
     */
    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                setFormField('position')(pos);
            },
            () => {
                setFormField('position')(null);
            }
        );
    }, [setFormField]);

    const setSiteIcon = useSetSiteIcon();
    const createSite = useCreateSite();
    const updateSite = useUpdateSite(site?.id);
    const updateSiteLocation = useUpdateSiteLocation(site?.id, formData.marker);

    const { loading: siteTagsLoading } = useUpsertSiteTags();

    /**
     * On load - populate site on edit.
     */
    React.useEffect(() => {
        if (!formData.name && site) {
            setFormData({
                ...formData,
                marker: { lat: site.latitude, lng: site.longitude },
                name: site.name,
                description: site?.description,
                typeCode: site?.typeCode,
                scopeOfWorks: site.scopeOfWorks,
                startDate: new Date(site.startDate),
                completionDate: new Date(site.completionDate),
                tags: site.tags || [],
            });
        }
    }, [formData, site]);

    /**
     * After updating site (since response may !== local marker)
     */
    React.useEffect(() => {
        if (site?.latitude) {
            setFormField('marker')({ lat: site.latitude, lng: site.longitude });
        }
    }, [setFormField, site?.latitude, site?.longitude]);

    const pageLoad =
        setSiteIcon.loading ||
        createSite.loading ||
        updateSite.loading ||
        updateSiteLocation.loading ||
        siteTagsLoading;

    async function onFormSubmit() {
        try {
            if (site) {
                await updateSite.execute({
                    name: formData.name,
                    description: formData.description,
                    scopeOfWorks: formData.scopeOfWorks,
                    typeCode: formData.typeCode,
                    startDate: formData.startDate.toISOString(),
                    completionDate: formData.completionDate.toISOString(),
                });
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { goBack } = history as any;
                goBack();
            } else {
                if (formData.createStep !== undefined && formData.createStep < 2) {
                    setFormField('createStep')(formData.createStep + 1);
                    return;
                }
                if (!formData.marker) {
                    setFormField('errorMessage')('Could not detect location for this site.');
                    return;
                }

                const [, newSite] = await createSite.execute({
                    name: formData.name,
                    description: formData.description,
                    scopeOfWorks: formData.scopeOfWorks,
                    startDate: formData.startDate,
                    completionDate: formData.completionDate,
                    typeCode: formData.typeCode,
                    icon: formData.icon,
                    latitude: formData.marker?.lat,
                    longitude: formData.marker?.lng,
                    tags: formData.tags,
                });
                if (newSite) {
                    history.push(`/site/${newSite.id}/members`);
                } else {
                    setFormField('errorMessage')('There was a problem creating this site.');
                }
            }
        } catch (error) {
            setFormField('errorMessage')(parseError(error));
        }
    }

    const canDelete = !site?.assetCount.IMG;

    const archiveOrDelete = (
        <div className="mt-6">
            <div className="text-lg mt-3 mb-2">Archive or Delete</div>
            <p className="text-sm text-gray-400">
                You may delete a site if you have not added any photos. Once the Site has photos
                associated, you may archive the site.
            </p>
            <div className="flex items-center space-x-2">
                <Link
                    to={`/delete-site?siteId=${site?.id}&archive=true`}
                    className={clsx('px-3 py-2 mt-3 rounded bg-primary flex items-center')}
                    type="button"
                >
                    Archive
                </Link>
                {canDelete ? (
                    <Link
                        to={`/delete-site?siteId=${site?.id}`}
                        className={clsx('px-3 py-2 mt-3 rounded bg-primary flex items-center', {})}
                        type="button"
                    >
                        Delete
                    </Link>
                ) : (
                    <div
                        className={clsx(
                            'px-3 py-2 mt-3 rounded bg-primary inline-flex items-center bg-opacity-25 cursor-default'
                        )}
                    >
                        Delete
                    </div>
                )}
            </div>
        </div>
    );

    const detailsValid = (() => {
        if (formData.createStep === 1 && !formData.marker) return false;
        return !!(
            formData.name &&
            formData.description &&
            formData.scopeOfWorks &&
            formData.startDate &&
            formData.completionDate &&
            formData.tags
        );
    })();

    const submitButtonDisabled = updateSite.loading || createSite.loading || !detailsValid;

    return (
        <SiteWrapper
            siteName={!site ? 'Create new site' : site?.name}
            onBack={(() => {
                if (site || !formData.createStep) return undefined;
                return () => setFormField('createStep')((formData.createStep as number) - 1);
            })()}
        >
            {pageLoad && <Loader page />}
            <div className="mx-2 mb-6 pb-6">
                {(site || formData.createStep === 2) && (
                    <ImageAndTagsStep formData={formData} setFormField={setFormField} site={site} />
                )}
                {(site || formData.createStep === 1) && (
                    <MapStep
                        formData={formData}
                        setFormField={setFormField}
                        google={google}
                        site={site}
                    />
                )}
                {(site || formData.createStep === 0) && (
                    <DetailsStep formData={formData} setFormField={setFormField} />
                )}
                {formData.errorMessage && (
                    <div className="text-red-600 my-3">{formData.errorMessage}</div>
                )}
                <button
                    className={clsx(
                        'ml-auto mr-3 px-3 py-2 mt-3 rounded bg-primary flex items-center',
                        { 'bg-opacity-25': submitButtonDisabled }
                    )}
                    onClick={onFormSubmit}
                    type="button"
                    disabled={submitButtonDisabled}
                >
                    {(() => {
                        if (site) return 'Save Changes';
                        if (!formData.createStep || formData.createStep < 2) return 'Next';
                        return 'Create';
                    })()}
                </button>
                {site && archiveOrDelete}
            </div>
        </SiteWrapper>
    );
}

export default function UpsertSiteContainer({ google }: { google: GoogleAPI }) {
    const params = useParams<{ id: string }>();
    const { id: siteId } = params;
    const { sites } = useSites();
    const history = useHistory();
    const site = sites.data?.find((s) => s.id === siteId);

    useEffect(() => {
        if (sites && !site && siteId) {
            history.push('/');
        }
    }, [history, site, siteId, sites]);

    if (siteId && !site) return null;

    return <UpsertSite site={site} google={google} />;
}
