import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import App from './App';
import './styles/_index.css';

const GTM_ID = process.env.REACT_APP_GTM_ID as string;
const history = createBrowserHistory();

if (GTM_ID) {
    ReactGA.initialize(GTM_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
}

history.listen(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
