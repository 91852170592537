import React from 'react';
import qs from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Loader } from '../components';
import { SiteResponse, useSiteDelete, useSites, useSiteArchive } from '../queries/sites';

function DeletedSite({ site, archive }: { site: SiteResponse | undefined; archive?: boolean }) {
    const deleteSite = useSiteDelete(site?.id);
    const siteArchive = useSiteArchive(site?.id);

    const history = useHistory();
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { goBack } = history as any;

    async function onDeleteOrArchive() {
        if (archive) {
            await siteArchive.execute();
        } else {
            await deleteSite.execute();
        }
    }

    const loading = deleteSite.loading || siteArchive.loading;

    if (!site || deleteSite.executed) {
        return (
            <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
                <h3 className="text-2xl">Site {archive ? 'Archived' : 'Deleted'}</h3>
                <p className="text-sm text-gray-400 my-2">
                    The site has been {archive ? 'Archived' : 'Deleted'}
                </p>
                <Link
                    to={site ? `/site/${site.id}` : '/'}
                    className={clsx(
                        'px-3 py-2 rounded m-1 bg-primary flex items-center w-16 justify-center'
                    )}
                >
                    Ok
                </Link>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
            <h3>Are you sure you want to {archive ? 'archive' : 'delete'} this site?</h3>
            <div className="flex space-x-4 mt-4">
                <button
                    type="button"
                    onClick={() => goBack()}
                    className="px-3 py-2 rounded m-1 bg-white text-black bg-opacity-75"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={onDeleteOrArchive}
                    disabled={loading}
                    className={clsx('px-3 py-2 rounded m-1 bg-red-500 flex items-center', {
                        'bg-opacity-25': loading,
                        'bg-opacity-75 hover:bg-opacity-100': !loading,
                    })}
                >
                    {archive ? 'Archive' : 'Delete'}
                    {loading && (
                        <div
                            className="relative w-8"
                            style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                        >
                            <Loader />
                        </div>
                    )}
                </button>
            </div>
        </div>
    );
}

export default function DeletedSiteContainer() {
    const location = useLocation();

    const params = qs.parse(location?.search.slice(1) as string);
    const { siteId, archive } = params;

    const { sites } = useSites();
    const site = sites.data?.find((s) => s.id === siteId);

    return <DeletedSite site={site} archive={!!archive} />;
}
