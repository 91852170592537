import clsx from 'clsx';
import React from 'react';
import './loader.css';

export default function Loader({
    page,
    children,
    overlay,
}: {
    overlay?: boolean;
    page?: boolean;
    children?: React.ReactNode;
}) {
    if (page) {
        return (
            <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-90">
                <div className="lds-spinner">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                {children}
            </div>
        );
    }
    return (
        <div
            className={clsx('absolute inset-0 flex flex-col items-center justify-center z-50', {
                'bg-black bg-opacity-75': overlay,
            })}
        >
            <div className="lds-spinner">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
            {children}
        </div>
    );
}
