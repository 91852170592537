import React, { useMemo } from 'react';

import { validatePassword } from '../utils';

export default function usePasswordValidation(
    password: string,
    disabled = false
): [string | React.ReactNode | undefined, (password: string) => string[]] {
    const passwordError = useMemo(() => {
        if (disabled) return undefined;
        const passwordValidations = validatePassword(password);
        if (passwordValidations.length === 0) return undefined;
        return (
            <div className="text-left">
                Your password must:
                <ul className="text-xs ml-5 list-disc">
                    {passwordValidations.map((validation, i) => (
                        <li key={i}>{validation}</li>
                    ))}
                </ul>
            </div>
        );
    }, [disabled, password]);

    return [passwordError, validatePassword];
}
