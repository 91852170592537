import React from 'react';
import { useHistory } from 'react-router-dom';

const HEADER_HEIGHT = 60;

export default function SiteWrapper({
    siteName,
    rightLink,
    children,
    onBack,
    disableBack,
}: {
    siteName: undefined | string;
    rightLink?: React.ReactNode;
    children: React.ReactNode;
    onBack?: () => void;
    disableBack?: boolean;
}) {
    const history = useHistory();
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { goBack } = history as any;

    return (
        <div>
            <div
                style={{ height: HEADER_HEIGHT }}
                className="fixed top-0 w-full z-100 bg-base flex flex-col z-10"
            >
                <div className="max-w-3xl mx-auto w-full h-full flex items-center">
                    {!disableBack && (
                        <button
                            type="button"
                            className="p-3 mx-1 flex items-center"
                            onClick={() => {
                                if (onBack) {
                                    onBack();
                                } else {
                                    goBack();
                                }
                            }}
                        >
                            <span className="material-icons">keyboard_backspace</span>
                        </button>
                    )}
                    <h1 className="text-2xl">{siteName || '...'}</h1>
                    {rightLink}
                </div>
            </div>
            <div
                style={{ top: HEADER_HEIGHT, minHeight: `calc(100vh - ${HEADER_HEIGHT}px)` }}
                className="fixed left-0 right-0 relative"
            >
                <div className="w-full h-full max-w-3xl mx-auto">{children}</div>
            </div>
        </div>
    );
}
