import React, { useState } from 'react';
import clsx from 'clsx';

export default function Input({
    value,
    label,
    onChange,
    type,
    disabled,
    password,
    ...props
}: {
    value: string;
    label: string | React.ReactNode;
    type?: 'text' | 'password' | 'email' | 'number';
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    password?: boolean;
    [rem: string]: unknown;
}) {
    const className = clsx(props, 'w-full block');

    const [showPassword, setShowPassword] = useState(false);

    return (
        <label className={className}>
            <span className="block text-opacity-75 text-white font-thin">{label}</span>
            <div className="relative flex items-center">
                <input
                    className="bg-black bg-opacity-50 p-2 rounded w-full"
                    onChange={onChange}
                    value={value}
                    type={(() => {
                        if (showPassword) return 'text';
                        if (type) return type;
                        return password ? 'password' : 'text';
                    })()}
                    disabled={disabled}
                />
                {password && (
                    <button
                        className="material-icons absolute right-0 p-2"
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? 'visibility' : 'visibility_off'}
                    </button>
                )}
            </div>
        </label>
    );
}
