import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useJobRoles } from '../../queries/references';
import { useUpdateUserProfilePic, useUserProfile, useUserProfileUpdate } from '../../queries/user';
import { ImageInput, Input, Loader } from '../../components';
import Select from '../../components/Select';
import SiteWrapper from '../site/SiteWrapper';
import { EMPTY_GUID, wait } from '../../utils';
import { getImageThumb } from '../../helpers/image';

export default function UpdateProfile() {
    const userProfile = useUserProfile();

    const user = userProfile?.data;

    const [form, setForm] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        city: '',
        jobRole: '',
        email: '',
    });
    const [profilePicId, setProfilePicId] = useState(undefined as File | undefined);

    useEffect(() => {
        if (user) {
            setForm({
                companyName: user.companyName || '',
                firstName: user.firstName,
                lastName: user.lastName,
                city: user.city || '',
                jobRole: user.jobRole || '',
                email: user.email,
            });
        }
    }, [user]);

    const jobRoles = useJobRoles();

    const userProfileUpdate = useUserProfileUpdate();
    const updateUserProfilePic = useUpdateUserProfilePic();

    const valid = (() => {
        if (userProfileUpdate.loading) {
            return false;
        }
        return !!(form.firstName && form.lastName && form.email && form.jobRole);
    })();

    const history = useHistory();
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { goBack } = history as any;

    async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        await userProfileUpdate.execute(form);
    }

    async function onClose() {
        await goBack();
        await wait(500);
        userProfileUpdate.reset();
        updateUserProfilePic.reset();
    }

    if (userProfileUpdate.success || updateUserProfilePic.success) {
        return (
            <SiteWrapper siteName="Profile">
                <div className="w-full max-w-lg mx-auto px-2 pt-2 pb-4">
                    <h3 className="text-xl mt-2 mb-2">Profile Updated</h3>
                    <p className="text-gray-400">Your profile has been successfully updated.</p>
                    <div className="flex mt-4 justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className={clsx(
                                'relative bg-primary py-2 px-4 rounded flex items-center bg-opacity-75 hover:bg-opacity-100'
                            )}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </SiteWrapper>
        );
    }

    return (
        <SiteWrapper siteName="Profile">
            <form onSubmit={onFormSubmit} className="w-full max-w-lg mx-auto px-2 pt-2 pb-4">
                <h3 className="text-xl mt-2 mb-2">Update Details</h3>
                {userProfileUpdate.error && (
                    <div className="text-red-600 my-3">{userProfileUpdate.error}</div>
                )}
                <div className="flex">
                    <ImageInput
                        value={profilePicId}
                        onChange={setProfilePicId}
                        mt-3
                        label="Profile Pic"
                        defaultImage={
                            user?.profilePicExtension &&
                            user?.profilePicId &&
                            getImageThumb(EMPTY_GUID, user?.profilePicId, user?.profilePicExtension)
                        }
                    />
                    <div className="text-gray-300 ml-3 flex flex-col justify-evenly items-start mt-10 mb-4">
                        <span className="text-sm">
                            Help other members identify your profile adding a profile picture
                        </span>
                        <button
                            className={clsx('px-3 py-2 mt-3 rounded bg-primary flex items-center', {
                                'bg-opacity-25 cursor-default':
                                    !profilePicId || updateUserProfilePic.loading,
                            })}
                            type="button"
                            onClick={() => updateUserProfilePic.execute(profilePicId)}
                            disabled={!profilePicId || updateUserProfilePic.loading}
                        >
                            {updateUserProfilePic.loading && (
                                <div
                                    // TODO: why not center aligned
                                    className="relative w-8 -mb-2"
                                    style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                                >
                                    <Loader />
                                </div>
                            )}
                            Update icon
                        </button>
                    </div>
                </div>
                <Input
                    label="Company"
                    mt-3
                    value={form.companyName}
                    onChange={(e) => setForm({ ...form, companyName: e.target.value })}
                />
                <Input
                    label="First Name"
                    mt-3
                    value={form.firstName}
                    onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                />
                <Input
                    label="Last Name"
                    mt-3
                    value={form.lastName}
                    onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                />
                <Input
                    label="City"
                    mt-3
                    value={form.city}
                    onChange={(e) => setForm({ ...form, city: e.target.value })}
                />
                <Select
                    label="Job Role"
                    options={
                        jobRoles.data?.map((jr) => ({
                            value: jr.code,
                            name: jr.name,
                        })) || []
                    }
                    mt-3
                    value={form.jobRole}
                    onChange={(e) => setForm({ ...form, jobRole: e.target.value })}
                />
                <Input
                    label="Email address"
                    mt-3
                    type="email"
                    value={form.email}
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
                <div className="flex mt-4 justify-end">
                    <button
                        type="submit"
                        disabled={!valid}
                        className={clsx('relative bg-primary py-2 px-4 rounded flex items-center', {
                            'bg-opacity-25': !valid,
                            'bg-opacity-75 hover:bg-opacity-100': valid,
                        })}
                    >
                        Save Changes
                        {userProfileUpdate.loading ? (
                            <div
                                // TODO: why not center aligned
                                className="relative w-8 -mb-2"
                                style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                            >
                                <Loader />
                            </div>
                        ) : (
                            <span className="material-icons ml-2">arrow_right_alt</span>
                        )}
                    </button>
                </div>
            </form>
        </SiteWrapper>
    );
}
