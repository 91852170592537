import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserActivity } from '../../queries/user';
import ImageGrid from '../../components/ImageGrid';

export default function Activity({ active }: { active: boolean }) {
    const [lastGallery, setLastGallery] = useState<string | undefined>();
    const [disableLoading, setDisableLoading] = useState<boolean>(false);
    const activity = useUserActivity(lastGallery);

    useEffect(() => {
        if (activity.data && !activity.data?.lastGallery) {
            setDisableLoading(true);
        } else {
            setLastGallery(activity.data?.lastGallery);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity.data?.lastGallery]);

    return (
        <>
            <ImageGrid
                sections={activity.data?.sections}
                disableLoading={!active || disableLoading}
                loading={activity.loading}
                emptyState={
                    <div className="text-center mt-4 text-grey-700">No recent activity</div>
                }
                onBottomScroll={() => {
                    activity.execute();
                }}
            />
            {!activity.loading && activity?.data?.sections.length === 0 && (
                <div className="text-center mt-8 text-xl font-thin max-w-xs mx-auto">
                    Welcome to SitePhoto, create a{' '}
                    <Link to="/site/add" className="underline">
                        new site
                    </Link>{' '}
                    or ask a colleague to invite you to their sites to get started.
                </div>
            )}
        </>
    );
}
