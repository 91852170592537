import { useState } from 'react';
import { useAxios } from '../axios-instance';
import mergeActivityResponse from '../helpers/merge-activity-response';
import { domain } from '../store';
import { AssetResponse, usePostAsset, refreshAll } from './assets';
import { ActivityResponse } from './user';

export type SiteResponse = {
    members: any;
    id: string;
    name: string;
    description: string;
    scopeOfWorks: string;
    icon?: string;
    iconExtension?: string;
    startDate: Date;
    isAdmin: boolean;
    completionDate: Date;
    latitude: 0;
    longitude: 0;
    tags?: string[];
    typeCode: string;
    memberCount: number;
    assetCount: {
        IMG: number;
    };
    ownedBy: {
        id: string;
        profilePicId: string;
        firstName: string;
        lastName: string;
        jobRole: string;
        email: string;
        countryCode: string;
        isAdmin: true;
    };
};

export function useSiteAssets(siteId: string) {
    return domain.useGet<AssetResponse>(`sites/${siteId}/assets`);
}

export function getSitesGenerator() {
    const sites = domain.get<SiteResponse[]>('sites');
    const recent = domain.get<SiteResponse[]>('sites/recent');
    const archived = domain.get<SiteResponse[]>('sites/archived');

    return {
        sites,
        recent,
        archived,
        execute: async () => {
            return Promise.all([sites.execute(), recent.execute(), archived.execute()]);
        },
    };
}

export const getSites = getSitesGenerator().execute;

export function useSites(disable = false) {
    const { sites, recent, archived, execute } = getSitesGenerator();

    return {
        sites: sites.useHook({ interval: 1000 * 60 * 5, lazy: disable }),
        recent: recent.useHook({ interval: 1000 * 60 * 5, lazy: disable }),
        archived: archived.useHook({ interval: 1000 * 60 * 5, lazy: disable }),
        execute,
    };
}

export function useSiteActivity(id?: string, before?: string) {
    const [data, setData] = useState<undefined | ActivityResponse>();
    const siteActivity = useAxios<ActivityResponse>({
        url: `sites/${id}/activityFeed`,
        params: {
            take: Number.parseInt(process.env.REACT_APP_DEFAULT_GALLERY_TAKE as string, 10) || 10,
            before,
        },
    });

    const siteActivityReload = useAxios<ActivityResponse>({
        url: `sites/${id}/activityFeed`,
        params: {
            take: Number.parseInt(process.env.REACT_APP_DEFAULT_GALLERY_TAKE as string, 10) || 10,
        },
    });

    async function execute(refresh?: boolean) {
        let result;
        if (refresh) {
            result = await siteActivityReload.execute();
            setData(result.data);
        } else {
            result = await siteActivity.execute();
            const { data: newData } = result;
            setData(mergeActivityResponse(data, newData));
        }
        return result;
    }

    return { ...siteActivity, execute, data };
}

export function useSetSiteIcon() {
    const setIcon = domain.usePost<{ siteId: string; assetId: string }, SiteResponse>(
        `sites/---/setIcon`,
        {
            transformRequest: ({ assetId, siteId }) => ({
                params: { assetId },
                url: `sites/${siteId}/setIcon`,
            }),
            multiple: true,
        }
    );

    const postAsset = usePostAsset(async (response, request) => {
        const siteId = request?.siteId as string;
        await refreshAll(siteId);
    });

    async function execute(siteId: string, icon: File) {
        return postAsset.execute({
            siteId,
            typeCode: 'IMG',
            file: icon,
            tags: [],
            takenAt: new Date().toISOString(),
            isIcon: true,
        });
    }

    return { ...setIcon, execute };
}

export type SitePostRequest = {
    name: string;
    description: string;
    scopeOfWorks: string;
    startDate: Date;
    completionDate: Date;
    latitude: number | undefined;
    longitude: number | undefined;
    typeCode: string;
    tags: string[];
    icon?: File | undefined;
};
export function useCreateSite() {
    const setSiteIcon = useSetSiteIcon();

    return domain.usePost<SitePostRequest, SiteResponse>(`sites`, {
        multiple: true,
        onSuccess: async (response, request) => {
            if (request?.icon) {
                await setSiteIcon.execute(response.id, request.icon);
            }
            await refreshAll();
        },
    });
}

export type SiteUpdateRequest = {
    name: string;
    description: string;
    scopeOfWorks: string;
    typeCode: string;
    startDate: string;
    completionDate: string;
};
export type SiteUpdateResponse = {
    id: string;
    accountId: string;
    name: string;
    description: string;
    typeCode: string;
    scopeOfWorks: string;
    iconId: string;
    iconExtension: string;
    startDate: string;
    completionDate: string;
    latitude: number;
    longitude: number;
    tags?: string[];
    archived: true;
    active: true;
    ownedBy: string;
    createdBy: string;
    createdAt: string;
};
export function useUpdateSite(siteId?: string) {
    return domain.usePut<SiteUpdateRequest, SiteUpdateResponse>(`sites/${siteId}`, {
        onSuccess: async () => {
            await refreshAll(siteId);
        },
    });
}

export type SiteTypeResponse = {
    code: string;
    name: string;
    active: boolean;
    createdAt: string;
};
export function useSiteTypes() {
    return domain.useGet<SiteTypeResponse[]>('references/siteTypes');
}

export function useUpdateSiteLocation(siteId?: string, location?: { lat: number; lng: number }) {
    return domain.usePost<void, SiteResponse>(
        `/sites/${siteId}/setLocation?latitude=${location?.lat}&longitude=${location?.lng}`,
        {
            onSuccess: async () => {
                if (siteId) {
                    await refreshAll(siteId);
                }
            },
        }
    );
}

export type Member = {
    id: string;
    firstName: string;
    lastName: string;
    jobRole: string;
    email: string;
    countryCode: string;
    city: string;
    isAdmin: boolean;
    companyName: string;
    profilePicExtension?: string;
    profilePicId?: string;
};
export type PendingInvitation = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    invitedAt: string;
};
export type SiteMembers = {
    members: Member[];
    pendingInvitations: PendingInvitation[];
};
export function useSiteMembers(siteId?: string) {
    return domain.useGet<SiteMembers>(`/sites/${siteId}/members`);
}

export function useResendInvitation(siteId?: string, invitationId?: string) {
    return domain.usePost<void, SiteMembers>(`/sites/${siteId}/invitations/${invitationId}/resend`);
}

export type SiteShareResponse = {
    siteId: string;
    name: string;
    url: string;
};
export function useGetSiteShare(siteId: string) {
    return domain.usePost<void, SiteShareResponse>(`/sites/${siteId}/shared`);
}

export function useSharedSite(siteId: string, token: string, before?: string) {
    const [data, setData] = useState<undefined | ActivityResponse>();

    const siteActivity = useAxios<ActivityResponse>({
        url: `/sites/${siteId}/sharedFeed?token=${token}`,
        params: {
            take: Number.parseInt(process.env.REACT_APP_DEFAULT_GALLERY_TAKE as string, 10) || 10,
            before,
        },
    });

    async function execute() {
        const result = await siteActivity.execute();
        const { data: newData } = result;

        setData(mergeActivityResponse(data, newData));

        return result;
    }

    return { ...siteActivity, execute, data };
}

export type InviteMemberRequest = {
    email: string;
    firstName: string;
    lastName: string;
    asAdmin: boolean;
};
export function useInviteMember(siteId?: string) {
    const siteMembers = useSiteMembers(siteId);
    return domain.usePost<InviteMemberRequest, SiteMembers>(`/sites/${siteId}/members/invite`, {
        onSuccess: async () => {
            await siteMembers.execute();
        },
    });
}

export function useSiteDelete(siteId?: string) {
    return domain.useDelete(`/sites/${siteId}`, {
        onSuccess: async () => {
            await refreshAll();
        },
    });
}
export function useSiteArchive(siteId?: string) {
    return domain.usePost(`/sites/${siteId}/archive`, {
        onSuccess: async () => {
            await refreshAll();
        },
    });
}

export function useRemoveSiteMember(siteId?: string, memberId?: string) {
    const siteMembers = useSiteMembers(siteId);
    return domain.useDelete(`/sites/${siteId}/members/${memberId}`, {
        onSuccess: async () => {
            await siteMembers.execute();
            await getSites();
        },
    });
}

export function useUpdateSiteMemberRole(siteId?: string, memberId?: string) {
    const siteMembers = useSiteMembers(siteId);
    return domain.usePost<boolean, SiteResponse>(`sites/${siteId}/members/${memberId}/changeRole`, {
        transformRequest: (isAdmin) => ({
            queryParams: {
                isAdmin,
            },
        }),
        onSuccess: async () => {
            await siteMembers.execute();
        },
    });
}
