import React from 'react';

export default function Checkbox({
    label,
    checked,
    setChecked,
}: {
    label: string;
    checked: boolean;
    setChecked: (checked: boolean) => void;
}) {
    return (
        <button
            type="button"
            onClick={() => setChecked(!checked)}
            className="flex items-center cursor-pointer"
        >
            <span className="material-icons text-2xl">
                {checked ? 'check_box' : 'check_box_outline_blank'}
            </span>
            <span className="ml-2 block text-opacity-75 text-white font-thin hover:text-opacity-100">
                {label}
            </span>
        </button>
    );
}
