import useAsync from '../helpers/use-async';
import { domain, useLocal } from '../store';
import { useUserAccount } from './account';
import { getSites } from './sites';
import { Asset } from './user';

export type AssetRequest = {
    siteId: string;
    typeCode: 'IMG' | 'VID';
    file: File;
    tags: string[];
    longitude?: number;
    latitude?: number;
    accuracy?: number;
    isIcon?: boolean;
    takenAt: string;
};
export type AssetResponse = {
    id: string;
    typeCode: 'IMG' | 'VID';
    mimeType: string;
    extension: string;
    tags?: string[];
    url: string;
    takenAt?: string;
};

export function usePostAsset(
    onSuccess?: (res: AssetResponse, req?: AssetRequest | undefined) => Promise<void>
) {
    return domain.usePost<AssetRequest, AssetResponse>(`/assets`, {
        multiple: true,
        onSuccess,
        transformRequest: (request) => {
            const formData = new FormData();
            formData.append('file', request.file, request.file.name);
            formData.append('typeCode', request.typeCode);
            formData.append('siteId', request.siteId);
            formData.append('takenAt', request.takenAt);

            // formData.append('tags', new Blob(request.tags, { type: 'octet/stream' }));
            request.tags.forEach((tag) => {
                formData.append('tags', tag);
            });
            if (request.longitude) {
                formData.append('longitude', request.longitude.toString());
            }
            if (request.latitude) {
                formData.append('latitude', request.latitude.toString());
            }
            if (request.accuracy) {
                formData.append('accuracy', request.accuracy.toString());
            }
            if (request.isIcon) {
                formData.append('isIcon', request.isIcon.toString());
            }

            return {
                data: formData,
                params: { isIcon: request.isIcon ? `true` : '' },
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
        },
    });
}

export type AssetComments = {
    id: string;
    assetId: string;
    message: string;
    createdAt: string;
    createdBy: {
        id: string;
        profilePicId: string;
        firstName: string;
        lastName: string;
        jobRole: string;
        email: string;
        countryCode: string;
        isAdmin: true;
    };
};
export function useAssetComments(id: string) {
    return domain.useGet<AssetComments[]>(`/assets/${id}/comments`, { interval: 10000 });
}

const favouriteAssets = domain.get<Asset[]>('/assets/favourites');
export const useFavouriteAssets = () => favouriteAssets.useHook();

export async function refreshAll(siteId?: string) {
    const promises: Promise<unknown>[] = [
        getSites(),
        /* getUserActivity.execute() */
        favouriteAssets.execute(),
    ];

    return Promise.all(
        siteId ? [...promises /* , getSiteActivityGenerator(siteId).execute() */] : promises
    );
}

export function usePostAssetComments(siteId: string, id: string) {
    const userAccount = useUserAccount();

    return domain.usePost<string, AssetComments[]>(`/assets/${id}/comments`, {
        transformRequest: (message) => ({
            data: { message },
        }),
        injectRequest: [
            {
                storeLocation: {
                    method: 'get',
                },
                parseRequestData: (state, request) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const message = (request as any).message as string;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const existingComments = ((state as any)?.data || []) as AssetComments[];
                    return [
                        ...existingComments,
                        {
                            id: existingComments.length,
                            message,
                            createdBy: userAccount.data?.me,
                        },
                    ];
                },
            },
        ],
        onSuccess: async () => {
            await refreshAll(siteId);
        },
    });
}

export function useSubmitAssets(siteId?: string) {
    const postAsset = usePostAsset();

    async function submitAssets({
        images,
        tags,
        callback,
        takenAt,
    }: {
        images?: File[];
        tags: string[][];
        callback: (i: number) => void;
        takenAt: Date;
    }) {
        if (!images || !siteId) return;
        try {
            let i = 0;
            // eslint-disable-next-line no-restricted-syntax
            for (const image of images) {
                // eslint-disable-next-line no-await-in-loop
                await postAsset.execute({
                    file: image as File,
                    siteId,
                    tags: tags[i],
                    takenAt: takenAt.toISOString(),
                    typeCode: 'IMG',
                });
                i += 1;
                callback(i);
            }
            await refreshAll(siteId);
        } catch (err) {
            //
        }
    }

    return submitAssets;
}

export function useFavouriteAsset(siteId: string, id: string) {
    const local = useLocal<boolean | undefined>(`/assets/${id}/favourite`);

    const { execute: favourite } = domain.usePost(`/assets/${id}/favourite`);
    const { execute: unFavourite } = domain.useDelete(`/assets/${id}/favourite`);

    async function post(setFavourite: boolean) {
        local.dispatch(setFavourite);
        if (setFavourite) {
            await favourite();
        } else {
            await unFavourite();
        }
        await favouriteAssets.execute();
        await refreshAll(siteId);
    }

    return { local: local.data, post };
}

export async function upsertSiteTags(siteId: string, tags: string[]) {
    await domain.post<string[], void>(`/sites/${siteId}/tags`).execute(tags);

    await refreshAll(siteId);
}

export function useUpsertSiteTags() {
    return useAsync(async (props: { siteId: string; tags: string[] }) => {
        await upsertSiteTags(props.siteId, props.tags);
    });
}

/**
 * List of tags within list of assets.
 */
export function useUpsertAssetsTags(siteId: string, assets: { id: string; tags?: string[] }[]) {
    return useAsync(async () => {
        await Promise.all(
            assets.map((asset) =>
                domain.post<string[], void>(`/assets/${asset.id}/tags`).execute(asset.tags)
            )
        );
        await refreshAll(siteId);
    });
}

export function useOwnedAssets() {
    return domain.useGet<AssetResponse[]>('/assets/owned');
}

export function useAssetDelete(id = '---', siteId?: string) {
    return domain.useDelete(`/assets/${id}`, {
        onSuccess: siteId
            ? async () => {
                  await refreshAll(siteId);
              }
            : undefined,
    });
}
