import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import * as assetApi from '../../queries/assets';
import { ActivitySection } from '../../queries/user';
import { useSites } from '../../queries/sites';
import { getImageOriginal, getImageThumb, getImageUrl } from '../../helpers/image';
import useDisableBodyScroll from '../../helpers/use-disable-body-scroll';
import Modal, { ModalAction } from '../Modal';
import { getQueryString, useQuery, wait } from '../../utils';
import DeleteModal from './DeleteModal';
import MultiSelectDeleteModal from './MultiSelectDeleteModal';
import PopupMenu from './PopupMenu';
import CommentsModal from './CommentsModal';
import useDoubleClick from '../../helpers/use-double-click';
import { useAuthedUser } from '../../queries/authentication';
import BulkTagsManagement from '../BulkTagsManagement';
import useMouseWheelZoom from '../../helpers/use-wheel-zoom';
import CustomAlert from './CustomAlert';
import { setImageDeleted } from '../../helpers/globalstate';

export type GalleryModalState = {
    galleryId: string;
    assetId: string;
    siteId: string;
};

const PREVIEW_HEIGHT = 100;
const PREVIEW_WIDTH = 80;

const HIDE_ARROWS = isMobile || isTablet;

function useCanDelete(siteId: string, createdBy: string | undefined, disableAdmin = false) {
    const { sites } = useSites(disableAdmin);
    const site = sites.data?.find((s) => s.id === siteId);
    const authedUser = useAuthedUser(disableAdmin);

    return !disableAdmin && (createdBy === authedUser?.id || !!site?.isAdmin);
}

export default function GalleryModal({
    sections,
    galleryModalState,
    onClose,
    setHasChanged,
    disableEdit,
    siteId,
}: {
    sections: ActivitySection[];
    galleryModalState: GalleryModalState;
    onClose: () => void;
    setHasChanged: () => void;
    disableEdit?: boolean;
    siteId: string;
}) {
    useDisableBodyScroll();

    const history = useHistory();
    const { goBack } = history as any;

    const [menuPopup, setMenuPopup] = useState(false);
    const { sites } = useSites();
    const site = sites.data?.find((s) => s.id === siteId);

    const previewImages = useRef<HTMLDivElement[]>([]);
    const [currentAssetId, setCurrentAssetId] = useState(galleryModalState.assetId);
    const [selectedAssets, setSelectedAssets] = useState<number[]>([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [commentsModal, setCommentsModal] = useState(false);
    const [managementModal, setManagementModal] = useState(false);
    const [showPreview, setShowPreview] = useState(
        !(isMobile && document.documentElement.clientWidth > document.documentElement.clientHeight)
    );

    const [isMultiSelectMode, setIsMultiSelectMode] = useState(false); // State for multi-select mode

    const [multiSelectDeleteModal, setMultiSelectDeleteModal] = useState(false);
    const [multiSelectSaveModal, setMultiSelectSaveModal] = useState(false);
    const [loading, setLoading] = useState(false);

    // Merge assets from all galleries and retain siteId
    const [allAssets, setAssets] = useState(
        sections.flatMap((section) =>
            section.galleries.flatMap((gallery) =>
                gallery.assets.map((asset) => ({
                    ...asset,
                    siteId: asset.siteId || gallery.siteId,
                }))
            )
        )
    );

    const index = allAssets.findIndex((a) => a.id === currentAssetId);

    function setIndex(assetIndex: number) {
        const asset = allAssets[assetIndex] || allAssets[0];
        if (asset) setCurrentAssetId(asset.id);
    }

    const asset = allAssets[index];

    const deleteAsset = assetApi.useAssetDelete(asset?.id, siteId);

    const query = useQuery();

    const { fullScreen } = query;

    const setFullScreen = useCallback(
        (fs: boolean) => {
            if (fs) {
                history.push(getQueryString({ ...query, fullScreen: fs }));
            } else {
                goBack();
            }
        },
        [history, query, goBack]
    );

    const exitFullScreen = useCallback(() => {
        if (fullScreen) {
            setFullScreen(false);
        }
    }, [fullScreen, setFullScreen]);

    const screenOrientation = useCallback(() => {
        setShowPreview(
            !(
                isMobile &&
                document.documentElement.clientWidth > document.documentElement.clientHeight
            )
        );
    }, []);

    useEffect(() => {
        window.addEventListener('resize', screenOrientation);
        return () => window.removeEventListener('resize', screenOrientation);
    }, [screenOrientation]);

    useEffect(() => {
        (async () => {
            await wait(1);
            const $image = previewImages.current[index];
            if ($image) {
                $image.scrollIntoView({ behavior: 'smooth' });
            }
        })();
    }, [allAssets, index]);

    const [showAlert, setShowAlert] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);

    const toggleSelectAsset = (assetId: number) => {
        setSelectedAssets((prevSelectedAssets) => {
            if (prevSelectedAssets.includes(assetId)) {
                return prevSelectedAssets.filter((id) => id !== assetId);
            }
            if (prevSelectedAssets.length < 10) {
                return [...prevSelectedAssets, assetId];
            }
            setShowAlert(true);
            return prevSelectedAssets;
        });
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setShowAlerts(false);
    };

    async function onDeleteAsset() {
        await deleteAsset.execute(); // Execute the delete operation
        const updatedAssets = allAssets.filter((a) => a.id !== asset.id);
        setAssets(updatedAssets); // Update the assets state
        setDeleteModal(false); // Close the delete confirmation modal
        setHasChanged(); // Mark that changes have been made

        // Signal that an image was deleted
        setImageDeleted(true);

        if (updatedAssets.length === 0) {
            onClose(); // Close the view if there are no more assets
        } else {
            setIndex(Math.min(index, updatedAssets.length - 1));
            setCurrentAssetId(updatedAssets[Math.min(index, updatedAssets.length - 1)].id);
        }
    }

    // hardcoded for now delete function
    const deleteAssets1 = assetApi.useAssetDelete(allAssets[selectedAssets[0]]?.id, siteId);
    const deleteAssets2 = assetApi.useAssetDelete(allAssets[selectedAssets[1]]?.id, siteId);
    const deleteAssets3 = assetApi.useAssetDelete(allAssets[selectedAssets[2]]?.id, siteId);
    const deleteAssets4 = assetApi.useAssetDelete(allAssets[selectedAssets[3]]?.id, siteId);
    const deleteAssets5 = assetApi.useAssetDelete(allAssets[selectedAssets[4]]?.id, siteId);
    const deleteAssets6 = assetApi.useAssetDelete(allAssets[selectedAssets[5]]?.id, siteId);
    const deleteAssets7 = assetApi.useAssetDelete(allAssets[selectedAssets[6]]?.id, siteId);
    const deleteAssets8 = assetApi.useAssetDelete(allAssets[selectedAssets[7]]?.id, siteId);
    const deleteAssets9 = assetApi.useAssetDelete(allAssets[selectedAssets[8]]?.id, siteId);
    const deleteAssets10 = assetApi.useAssetDelete(allAssets[selectedAssets[9]]?.id, siteId);
    async function onDeleteSelectedAssets() {
        setLoading(true);
        try {
            try {
                await deleteAssets1.execute();
                await deleteAssets2.execute();
                await deleteAssets3.execute();
                await deleteAssets4.execute();
                await deleteAssets5.execute();
                await deleteAssets6.execute();
                await deleteAssets7.execute();
                await deleteAssets8.execute();
                await deleteAssets9.execute();
                await deleteAssets10.execute();
                setImageDeleted(true);
            } catch (error) {
                console.error(`Error`, error);
            }
            // Clear the selected assets after deletion
            const updatedAssets = allAssets.filter((_, indexs) => !selectedAssets.includes(indexs));
            setAssets(updatedAssets);
            setSelectedAssets([]);
            setHasChanged();
            setMultiSelectDeleteModal(false);
            setDeleteModal(false);
            setLoading(false);
            setIsMultiSelectMode(false);
            if (updatedAssets.length === 0) {
                onClose();
            } else {
                setIndex(Math.min(index, updatedAssets.length - 1));
                setCurrentAssetId(updatedAssets[Math.min(index, updatedAssets.length - 1)].id);
            }
        } catch (error) {
            console.error('Error during bulk deletion:', error);
            setLoading(false);
        }
    }

    async function MultidownloadFunction() {
        // Set the loading state
        setLoading(true);
        let counter = 1;

        // Map over the selected assets and create an array of promises for each download
        const downloadPromises = selectedAssets.map(async (selectedIndex) => {
            const assets = allAssets[selectedIndex];
            if (assets) {
                try {
                    const response = await fetch(
                        getImageOriginal(siteId, assets.id, assets.extension)
                    );
                    const blob = await response.blob();

                    // Fallback method for downloading the image
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = `${site?.name}-${counter}.${assets.extension}`;

                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(url); // Clean up after download
                    document.body.removeChild(link);
                    console.log(`Image ${counter} downloaded successfully`);

                    counter += 1; // Increment counter for the next image name
                } catch (error) {
                    console.error(`Download failed for image ${counter}:`, error);
                }
            }
        });

        // Execute all download promises concurrently
        await Promise.all(downloadPromises);

        // Reset the UI state after all downloads are complete
        setIsMultiSelectMode(false);
        setMultiSelectSaveModal(false);
        setLoading(false);
    }

    const galleryImages = useMemo(() => {
        return R.uniqBy(
            (si) => si.original,
            allAssets.map((si) => {
                const siteData = sites.data?.find((s) => s.id === si.siteId);
                return {
                    original: getImageUrl(si.siteId || siteId, si.id, si.extension),
                    thumbnail: getImageThumb(si.siteId || siteId, si.id, si.extension),
                    siteName: siteData?.name,
                    takenAt: si.takenAt,
                    createdAt: si.createdAt,
                    tags: si.tags || [],
                };
            })
        );
    }, [allAssets, siteId, sites.data]);
    // removes the tick when not in multi select
    useEffect(() => {
        if (!isMultiSelectMode) {
            setSelectedAssets([]);
        }
    }, [isMultiSelectMode]);

    const preview = showPreview && (
        <div className="fixed bottom-0 left-0 right-0 flex" style={{ height: PREVIEW_HEIGHT }}>
            <div className="flex mx-auto items-center flex-no-wrap overflow-x-auto overflow-y-hidden">
                {galleryImages.map((image, i) => {
                    const active = i === index;
                    const isSelected = selectedAssets.includes(i);
                    return (
                        <div
                            ref={(el) => {
                                (previewImages.current as any)[i] = el;
                            }}
                            style={{
                                backgroundImage: `url(${image.thumbnail})`,
                                backgroundPosition: 'center',
                                minWidth: PREVIEW_WIDTH,
                                width: PREVIEW_WIDTH,
                                minHeight: 60,
                                height: 60,
                            }}
                            onClick={() => {
                                if (isMultiSelectMode) {
                                    toggleSelectAsset(i);
                                } else {
                                    setIndex(i);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    if (isMultiSelectMode) {
                                        toggleSelectAsset(i);
                                    } else {
                                        setIndex(i);
                                    }
                                }
                            }}
                            role="button"
                            aria-label={isMultiSelectMode ? 'Select image' : 'Navigate to image'}
                            tabIndex={0}
                            className={clsx('relative border-2', {
                                'border-white': active,
                                'border-black': !active,
                            })}
                            key={image.thumbnail}
                        >
                            {isSelected && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: 'green',
                                        color: 'white',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    ✓
                                </div>
                            )}
                            {!isSelected && isMultiSelectMode && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: 'grey',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );

    const containerRef = useMouseWheelZoom((zoomIn) => {
        setFullScreen(true);
    }, exitFullScreen);
    const canDelete = useCanDelete(siteId, asset?.createdBy?.id, disableEdit);

    if (!asset || !galleryImages) return null;

    if (fullScreen) {
        return (
            <Modal
                zoomable
                actions={[
                    {
                        onClick: () => {
                            setFullScreen(!fullScreen);
                        },
                        icon: 'close',
                    },
                ]}
            >
                <TransformWrapper>
                    <TransformComponent>
                        <div
                            style={{
                                width: '100vw',
                                height: '100vh',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={galleryImages[index].original}
                                alt="test"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                            />
                        </div>
                    </TransformComponent>
                </TransformWrapper>
            </Modal>
        );
    }

    return (
        <Modal
            actions={
                commentsModal
                    ? undefined
                    : ([
                          {
                              icon: 'arrow_back',
                              left: true,
                              onClick: () => onClose(),
                          },
                          !disableEdit &&
                              !isMultiSelectMode && {
                                  icon: 'comment',
                                  indicator: asset.comments,
                                  onClick: () => setCommentsModal(true),
                              },
                          !disableEdit &&
                              !isMultiSelectMode && {
                                  icon: 'local_offer',
                                  name: 'Tags',
                                  onClick: () => setManagementModal(true),
                              },
                          isMultiSelectMode &&
                              !isMobile && {
                                  icon: 'get_app',
                                  onClick: () => {
                                      if (selectedAssets.length > 0) {
                                          setMultiSelectSaveModal(true);
                                      } else {
                                          setShowAlerts(true);
                                      }
                                  },
                              },
                          isMultiSelectMode &&
                              canDelete && {
                                  icon: 'delete',
                                  onClick: () => {
                                      if (selectedAssets.length > 0) {
                                          setMultiSelectDeleteModal(true);
                                      } else {
                                          setShowAlerts(true);
                                      }
                                  },
                              },
                          !isMobile && {
                              icon: isMultiSelectMode ? 'close' : undefined, // 'close' icon is used for 'X'
                              text: isMultiSelectMode ? undefined : 'Select',
                              onClick: () => setIsMultiSelectMode((prev) => !prev),
                              name: isMultiSelectMode
                                  ? 'Disable Multi-Select'
                                  : 'Enable Multi-Select',
                          },
                          isMobile &&
                              canDelete && {
                                  icon: isMultiSelectMode ? 'close' : undefined, // 'close' icon is used for 'X'
                                  text: isMultiSelectMode ? undefined : 'Select',
                                  onClick: () => setIsMultiSelectMode((prev) => !prev),
                                  name: isMultiSelectMode
                                      ? 'Disable Multi-Select'
                                      : 'Enable Multi-Select',
                              },
                          !isMultiSelectMode && {
                              onClick: () => setMenuPopup(true),
                              icon: 'more_vert',
                          },
                      ].filter((t) => t) as ModalAction[])
            }
        >
            {commentsModal && (
                <CommentsModal
                    onClose={() => setCommentsModal(false)}
                    assetId={asset.id}
                    siteId={siteId}
                />
            )}
            {multiSelectDeleteModal && (
                <MultiSelectDeleteModal
                    onCancel={() => setMultiSelectDeleteModal(false)}
                    onDelete={onDeleteSelectedAssets}
                    loading={loading}
                />
            )}
            {/* MultiSelectSaveModal, reworked the multiselectDeleteModal to be a multiselectSaveModal */}
            {multiSelectSaveModal && (
                <MultiSelectDeleteModal
                    onCancel={() => setMultiSelectSaveModal(false)}
                    onDelete={MultidownloadFunction}
                    loading={loading}
                    confirmText="Are you sure you want to save these selected photos?"
                    deleteText="Download"
                />
            )}
            {menuPopup && (
                <PopupMenu
                    onClose={() => setMenuPopup(false)}
                    openDeleteModal={() => setDeleteModal(true)}
                    canDelete={canDelete}
                    asset={asset}
                    siteId={siteId}
                    disableEdit={disableEdit}
                />
            )}
            {managementModal && (
                <BulkTagsManagement
                    defaultImageIndex={index}
                    onClose={() => setManagementModal(false)}
                    assetsRef={allAssets}
                    assets={allAssets.map((a, i) => ({
                        id: a.id,
                        imageUrl: galleryImages[i]?.original,
                    }))}
                    tags={allAssets.map((a) => a.tags || [])}
                    siteId={siteId}
                    isOpen
                />
            )}
            {deleteModal && (
                <DeleteModal
                    onCancel={() => setDeleteModal(false)}
                    onDelete={() => onDeleteAsset()}
                    loading={deleteAsset.loading}
                />
            )}
            {showAlert && (
                <CustomAlert
                    message="Only 10 images can be selected at a time."
                    onClose={handleCloseAlert}
                />
            )}
            {showAlerts && (
                <CustomAlert message="Please select an image." onClose={handleCloseAlert} />
            )}
            <div ref={containerRef}>
                <SwipeableViews index={index} onChangeIndex={setIndex}>
                    {galleryImages.map((image, i) => (
                        <div key={image.original}>
                            <div
                                key={image.original}
                                className="flex items-center justify-center relative"
                                style={{
                                    height: `calc(100vh - ${
                                        showPreview ? PREVIEW_HEIGHT + 20 : 0
                                    }px)`,
                                    marginBottom: showPreview ? PREVIEW_HEIGHT : 0,
                                    marginTop: 25,
                                }}
                                onClick={() => {
                                    if (isMultiSelectMode) {
                                        toggleSelectAsset(i);
                                    } else {
                                        setIndex(i);
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        if (isMultiSelectMode) {
                                            toggleSelectAsset(i);
                                        } else {
                                            setIndex(i);
                                        }
                                    }
                                }}
                                role="button"
                                tabIndex={0}
                                aria-label={
                                    isMultiSelectMode ? 'Select image' : 'Navigate to image'
                                }
                            >
                                <div className="absolute top-0 left-0 mt-5 sm:mt-10 p-5 bg-black bg-opacity-50">
                                    {image.siteName},{' '}
                                    {(image.takenAt &&
                                        format(new Date(image.takenAt), 'd MMM yyyy')) ||
                                        (image.createdAt &&
                                            format(new Date(image.createdAt), 'd MMM yyyy'))}
                                    <div className="text-sm opacity-50 text-grey-700">
                                        {image.tags.join(', ')}
                                    </div>
                                </div>
                                <img
                                    src={image.original}
                                    alt={image.original}
                                    className="max-w-full max-h-full"
                                />
                                {selectedAssets.includes(i) && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: 'green',
                                            color: 'white',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        ✓
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </SwipeableViews>
                <div
                    className={clsx(
                        'w-full max-w-7xl h-full fixed inset-0 mx-auto flex items-center justify-between px-2',
                        {
                            hidden: HIDE_ARROWS,
                        }
                    )}
                >
                    <button
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIndex(index - 1);
                        }}
                        className={clsx(
                            'ml-1 h-10 w-10 bg-gray-500 bg-opacity-25 inline-flex items-center justify-center rounded-full text-gray-400 hover:text-white',
                            { invisible: index === 0 }
                        )}
                    >
                        <span className="material-icons text-4xl">navigate_before</span>
                    </button>
                    <button
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIndex(index + 1);
                        }}
                        className={clsx(
                            'ml-1 h-10 w-10 bg-gray-500 bg-opacity-25 inline-flex items-center justify-center rounded-full text-gray-400 hover:text-white',
                            { invisible: index >= galleryImages.length - 1 }
                        )}
                    >
                        <span className="material-icons text-4xl">navigate_next</span>
                    </button>
                </div>
            </div>
            {preview}
        </Modal>
    );
}
