import { useState } from 'react';
import { useAxios } from '../axios-instance';
import mergeActivityResponse from '../helpers/merge-activity-response';
import { domain } from '../store';
import { useUserAccount } from './account';

export type Asset = {
    id: string;
    typeCode: string;
    mimeType: string;
    url: string;
    tags?: string[];
    latitude?: number;
    longitude?: number;
    favourite: boolean;
    createdAt: string;
    extension: string;
    comments: number;
    siteId?: string;
    takenAt?: string;
    createdBy: {
        id: string;
        profilePicId: string;
        firstName: string;
        lastName: string;
        jobRole: string;
        email: string;
        countryCode: string;
        isAdmin: true;
    };
};

export type ActivitySection = {
    title: string;
    galleries: {
        id: string;
        siteId: string;
        siteName: string;
        startDate: Date;
        start: string;
        endDate: Date;
        createdBy: {
            id: string;
            profilePicId: string;
            firstName: string;
            lastName: string;
            jobRole: string;
            email: string;
            countryCode: string;
            isAdmin: boolean;
        };
        assetCount: {
            IMG: number;
        };
        assets: Asset[];
    }[];
};

export type User = {
    id: string;
    profilePicId?: string;
    profilePicExtension?: string;
    firstName: string;
    lastName: string;
    jobRole?: string;
    email: string;
    countryCode?: string;
    isAdmin: boolean;
    companyName?: string;
    city?: string;
};

export type ActivityResponse = {
    lastGallery?: string;
    sections: ActivitySection[];
};

export function useUserActivity(before?: string) {
    const [data, setData] = useState<undefined | ActivityResponse>();

    const userActivity = useAxios<ActivityResponse>({
        url: `user/activityFeed`,
        params: {
            take: Number.parseInt(process.env.REACT_APP_DEFAULT_GALLERY_TAKE as string, 10) || 10,
            before,
        },
    });

    async function execute() {
        const result = await userActivity.execute();
        const { data: newData } = result;

        setData(mergeActivityResponse(data, newData));

        return result;
    }

    return { ...userActivity, execute, data };
}

export function useUpdateUserProfilePic() {
    const userAccount = useUserAccount();
    return domain.usePost<File, UserProfileUpdateResponse>(`/user/profilePic`, {
        transformRequest: (request) => {
            const formData = new FormData();
            formData.append('file', request, request.name);

            return {
                data: formData,
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
        },
        onSuccess: async () => {
            await userAccount.execute();
        },
    });
}

export type UserProfileUpdateRequest = {
    companyName: string;
    firstName: string;
    lastName: string;
    city: string;
    jobRole: string;
    email: string;
};
export type UserProfileUpdateResponse = {
    id: string;
    profilePicId: string;
    companyName: string;
    firstName: string;
    lastName: string;
    jobRole: string;
    email: string;
    city: string;
    countryCode: string;
    isAdmin: boolean;
};

export function useUserProfile() {
    return domain.useGet<User>(`/user/profile`);
}

export function useUserProfileUpdate() {
    const userAccount = useUserAccount();
    return domain.usePut<UserProfileUpdateRequest, UserProfileUpdateResponse>('/user/profile', {
        transformRequest: (req) => ({
            data: req as UserProfileUpdateRequest,
        }),
        onSuccess: async () => {
            await userAccount.execute();
        },
    });
}

export type UserChangePasswordRequest = {
    currentPassword: string;
    newPassword: string;
};
export function useUserPasswordChange() {
    return domain.usePut<UserChangePasswordRequest, boolean>('/user/changePassword');
}
