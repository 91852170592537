import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/logo512.png';
import Tabber from './Tabber';
import tabs from './tabs';
import { logout } from '../../store';
import { getQueryString, useQuery } from '../../utils';
import { useAuthedUser } from '../../queries/authentication';

const HEADER_HEIGHT = 105;

export default function Main() {
    const authedUser = useAuthedUser();
    const history = useHistory();

    const query = useQuery();

    const defaultTabIndex = React.useMemo(() => {
        const tabParam = query.tab;

        const index = tabs.findIndex((tab) => tab.link === tabParam);
        if (index >= 0) return index;
        return tabs.findIndex((tab) => tab.default);
    }, [query]);

    const [tabIndex, _setTabIndex] = useState(defaultTabIndex);

    function setTabIndex(index: number) {
        _setTabIndex(index);
        history.push(getQueryString({ tab: tabs[index].link }));
    }

    const tab = tabs[tabIndex];

    return (
        <>
            <div
                style={{ height: HEADER_HEIGHT }}
                className="fixed top-0 w-full z-100 bg-base flex flex-col z-10"
            >
                <div className="max-w-3xl mx-auto w-full h-full flex flex-col">
                    <div className="flex px-3 pt-3 pb-2 items-center text-white max-w-3xl mx-auto w-full">
                        <img src={logo} alt="logo" className="h-8" />
                        <h1 className="text-2xl ml-3">SitePhoto</h1>
                        <button type="button" className="ml-auto flex" onClick={() => logout()}>
                            <span className="material-icons">login</span>
                        </button>
                    </div>
                    <Tabber tabIndex={tabIndex} onChange={setTabIndex} />
                </div>
            </div>

            {(tab?.name !== 'Sites' || (tab?.name === 'Sites' && authedUser?.account_id)) &&
                tab?.floatButton}
            <div
                style={{
                    top: HEADER_HEIGHT,
                    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
                }}
                className="fixed left-0 right-0 relative"
            >
                <div className="w-full max-w-3xl mx-auto h-full">
                    <SwipeableViews onChangeIndex={setTabIndex} index={tabIndex}>
                        {tabs.map((t, ti) => (
                            <div key={t.name}>
                                <t.Component active={tabIndex === ti} />
                            </div>
                        ))}
                    </SwipeableViews>
                </div>
            </div>
        </>
    );
}
