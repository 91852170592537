/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from '../../components';
import Image from '../../components/Image';
import { getImageThumb } from '../../helpers/image';
import {
    useSiteMembers,
    useSites,
    useRemoveSiteMember,
    useUpdateSiteMemberRole,
} from '../../queries/sites';
import { useJobRoles } from '../../queries/references';
import { EMPTY_GUID } from '../../utils';
import SiteWrapper from './SiteWrapper';

export default function UpdateSiteMember() {
    const { sites } = useSites();

    const params = useParams<{ id: string; siteId: string }>();
    const site = sites.data?.find((s) => s.id === params.siteId);
    const history = useHistory();
    const jobRoles = useJobRoles();
    const siteMembers = useSiteMembers(site?.id);
    const siteMember = siteMembers.data?.members.find((m) => m.id === params.id);
    const jobRole = jobRoles.data?.find((s) => s.code === siteMember?.jobRole);
    const removeSiteMember = useRemoveSiteMember(site?.id, siteMember?.id);
    const updateSiteMemberRole = useUpdateSiteMemberRole(site?.id, siteMember?.id);

    const [modal, setModal] = useState<'remove' | 'admin' | undefined>(undefined);

    useEffect(() => {
        if ((sites && !site) || (siteMembers?.data && !siteMember)) {
            history.push('/');
        }
    }, [history, site, siteMember, siteMembers?.data, sites]);

    const deleteModal = (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
            <h3>Are you sure you want to remove this member?</h3>
            <div className="flex space-x-4 mt-4">
                <button
                    type="button"
                    onClick={() => setModal(undefined)}
                    className="px-3 py-2 rounded m-1 bg-white text-black bg-opacity-75"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={() => removeSiteMember.execute()}
                    className="px-3 py-2 rounded m-1 bg-red-500"
                >
                    Delete
                </button>
            </div>
        </div>
    );

    function updateMemberRole() {
        updateSiteMemberRole.execute(!siteMember?.isAdmin);
        setModal(undefined);
    }

    const adminModal = (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-90 text-white z-50">
            <h3 className="text-center">
                {siteMember?.isAdmin
                    ? 'Are you sure you want to remove admin for this member?'
                    : 'Are you sure you want to make this member an admin?'}
            </h3>
            <div className="flex space-x-4 mt-4">
                <button
                    type="button"
                    onClick={() => setModal(undefined)}
                    className="px-3 py-2 rounded m-1 bg-white text-black bg-opacity-75"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={updateMemberRole}
                    className="px-3 py-2 rounded m-1 bg-blue-500"
                >
                    {siteMember?.isAdmin ? 'Remove admin' : 'Make admin'}
                </button>
            </div>
        </div>
    );

    return (
        <SiteWrapper siteName={site?.name}>
            {modal === 'remove' && deleteModal}
            {modal === 'admin' && adminModal}
            {(removeSiteMember.loading || updateSiteMemberRole.loading) && <Loader page />}
            {siteMember && (
                <div className="p-3 flex flex-col">
                    <div className="flex items-center">
                        <Image
                            src={
                                siteMember?.profilePicExtension &&
                                siteMember?.profilePicId &&
                                getImageThumb(
                                    EMPTY_GUID,
                                    siteMember?.profilePicId,
                                    siteMember?.profilePicExtension
                                )
                            }
                            size="80px"
                            alt="img"
                        />
                        <div className="flex flex-col justify-between ml-3">
                            <div>
                                <div className="text-2xl">
                                    {siteMember.firstName} {siteMember.lastName}
                                </div>
                                <div className="text-gray-400 text-sm">
                                    {siteMember.email.toLowerCase()}
                                </div>
                            </div>
                            {site?.isAdmin && (
                                <div className="flex items-center space-x-2 mt-2">
                                    <button
                                        type="button"
                                        className="border border-gray-400 text-gray-300 hover:text-white hover:border-gray-200 inline-block px-1 rounded"
                                        onClick={() => setModal('remove')}
                                    >
                                        Remove Member
                                    </button>
                                    <button
                                        type="button"
                                        className="border border-gray-400 text-gray-300 hover:text-white hover:border-gray-200 inline-block px-1 rounded"
                                        onClick={() => setModal('admin')}
                                    >
                                        {siteMember.isAdmin ? 'Remove Admin' : 'Make Admin'}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col space-y-1 mt-4">
                        <div className="flex">
                            <span className="w-24 text-gray-400">Company</span>
                            <div>{siteMember.companyName ? siteMember.companyName : '-'}</div>
                        </div>
                        <div className="flex">
                            <span className="w-24 text-gray-400">Role</span>
                            <div>{jobRole ? jobRole.name : '-'}</div>
                        </div>
                        <div className="flex">
                            <span className="w-24 text-gray-400">City/Town</span>
                            <div>{siteMember.city ? siteMember.city : '-'}</div>
                        </div>
                    </div>
                </div>
            )}
        </SiteWrapper>
    );
}
