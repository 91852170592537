import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Input, Loader } from '../../components';
import logo from '../../assets/images/logo512.png';
import { useLogin } from '../../queries/authentication';
import Checkbox from '../../components/Checkbox';
import usePasswordValidation from '../../helpers/use-password-validation';

export default function Login() {
    const [email, setEmail] = useState(process.env.REACT_APP_DEV_EMAIL || '');
    const [password, setPassword] = useState(process.env.REACT_APP_DEV_PASSWORD || '');
    const login = useLogin();
    const history = useHistory();

    const [submitted, setSubmitted] = useState(false);

    const [passwordError, validatePassword] = usePasswordValidation(password, !submitted);

    const [longLived, setLongLived] = useState(false);

    async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setSubmitted(true);
        login.reset();

        if (validatePassword(password).length > 0) {
            return;
        }

        const [err] = await login.execute({ email, password, longLived });
        if (!err) {
            history.push('/');
        }
    }

    const error = login.error || passwordError;

    return (
        <div className="flex">
            <form
                onSubmit={onFormSubmit}
                className="w-72 min-h-screen max-w-full mx-auto px-2 pt-8 pb-2 text-lg flex flex-col"
            >
                <img src={logo} alt="logo" className="h-32 mx-auto" />
                <h1 className="text-4xl text-center text-white mt-3 mb-6">SitePhoto</h1>
                {error && <div className="text-red-600 my-3">{error}</div>}
                <Input
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    label="Password"
                    type="password"
                    value={password}
                    mt-3
                    password
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="mt-3">
                    <Checkbox
                        label="Stay signed in?"
                        checked={longLived}
                        setChecked={setLongLived}
                    />
                </div>
                <div className="flex items-center justify-between mt-4 mb-2">
                    <button
                        type="submit"
                        className="button bg-primary py-2 px-4 rounded bg-opacity-75 hover:bg-opacity-100 flex items-center"
                    >
                        Sign in
                        {login.loading ? (
                            <div
                                className="relative w-8"
                                style={{ transform: 'scale(0.3)', marginTop: '-27%' }}
                            >
                                <Loader />
                            </div>
                        ) : (
                            <span className="material-icons ml-2">arrow_right_alt</span>
                        )}
                    </button>
                </div>
                <div className="flex-1 flex items-end justify-center my-2">
                    <Link
                        to="/forgot-password"
                        className="inline-block hover:underline text-sm p-1"
                    >
                        Forgot your password?
                    </Link>
                </div>
            </form>
        </div>
    );
}
