import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { GoogleAPI, GoogleApiWrapper } from 'google-maps-react';
import routes, { LocalRoute } from './routes';
import { joinUrl } from './utils';
import { Provider } from './store';

import AuthWrapper from './wrappers/AuthWrapper';
import LayoutWrapper from './wrappers/LayoutWrapper';
import SetupWrapper from './wrappers/SetupWrapper';

function renderRoute(route: LocalRoute, parentPath = '', google: GoogleAPI) {
    const component = (
        <route.Component google={google}>
            {route.routes && route.routes.map((r) => renderRoute(r, route.path, google))}
        </route.Component>
    );

    return (
        <Route key={route.path} path={joinUrl(parentPath, route.path)} exact={route.path === '/'}>
            {component}
        </Route>
    );
}

function App({ google }: { google: GoogleAPI }) {
    return (
        // D-man Store / wrapper
        <Provider>
            {/* Routing */}
            <BrowserRouter>
                {/* Initial setup that requires routing + store */}
                <SetupWrapper>
                    {/* Layouts */}
                    <LayoutWrapper>
                        {/* Protected routes */}
                        <AuthWrapper>
                            <Switch>
                                {routes.map((route) => renderRoute(route, undefined, google))}
                            </Switch>
                        </AuthWrapper>
                    </LayoutWrapper>
                </SetupWrapper>
            </BrowserRouter>
        </Provider>
    );
}

const GoogleWrapped = GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
})(App);

export default () => (
    <>
        <GoogleWrapped />
    </>
);
