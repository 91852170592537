import { ActivityResponse, ActivitySection } from '../queries/user';

/**
 * Merges activities.  Assumes that galleries within sections are unique.
 */
export default function mergeActivityResponse(
    oldActivity: ActivityResponse | undefined,
    newActivity: ActivityResponse
) {
    const existingSections = oldActivity?.sections;
    const sections = (() => {
        if (!existingSections) return newActivity.sections;
        const newSections: ActivitySection[] = [...existingSections];
        newActivity.sections.forEach((newSection) => {
            const oldSection = existingSections.find((s) => s.title === newSection.title);
            if (!oldSection) {
                newSections.push(newSection);
            } else {
                oldSection.title = newSection.title;
                oldSection.galleries = [...oldSection.galleries, ...newSection.galleries];
            }
        });
        return newSections;
    })();

    return {
        sections,
        lastGallery: newActivity.lastGallery,
    };
}
