import { createSelector } from 'reselect';
import * as R from 'ramda';
import { createDMan } from 'd-man';
// eslint-disable-next-line import/no-unresolved
import { DomainState, RootState, StoreState } from 'd-man/dist/store/reducer';

import { LoginResponse } from './queries/authentication';
import history from './history';
import { parseError } from './utils';

const loginSelector = createSelector(
    (state: RootState) => state.default,
    (domain: DomainState) =>
        R.path<StoreState<LoginResponse>>(['authentication/login', 'post'], domain)
);

export function getAuthToken(state: RootState) {
    const login = loginSelector(state);
    return login ? `Bearer ${login?.data?.token}` : undefined;
}

const dMan = createDMan({
    domain: {
        baseURL: process.env.REACT_APP_API_URL as string,
        getAuthToken,
        useResponseInterceptor: {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onError: errorInterceptor,
        },
    },
    localStorageKey: 'sitephoto',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorInterceptor(error: any) {
    if (error.response && error.response.status === 404) {
        history.push('/global-error');
    } else if (error.response && error.response.status === 401) {
        dMan.logout();
        history.push('/login');
    }
    return Promise.reject(parseError(error));
}

const { domain, logout, Provider, useLocal } = dMan;

export { domain, logout, Provider, useLocal };
