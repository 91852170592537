import { domain } from '../store';
import { useAuthedUser } from './authentication';

export type User = {
    id: string;
    profilePicId?: string;
    profilePicExtension?: string;
    firstName: string;
    lastName: string;
    jobRole?: string;
    email: string;
    countryCode?: string;
    isAdmin: boolean;
    companyName?: string;
    city?: string;
};

export type Account = {
    id: string;
    name: string;
    companyRegistration: string;
    companyVat: string;
    companyWebsite: string;
    users: User[];
    me?: User;
};
export function useUserAccount() {
    const account = domain.useGet<Account>(`/account`);
    const authedUser = useAuthedUser();

    const me = account?.data?.users.find((u) => u.id === authedUser?.id);

    const data: Account | undefined = me && account?.data ? { ...account.data, me } : account.data;

    return {
        ...account,
        data,
    };
}
