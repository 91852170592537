import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Portal from './Portal';

export type ModalAction = {
    icon?: string;
    text?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    indicator?: number;
    left?: boolean;
};

export default function Modal({
    children,
    actions,
    zoomable,
}: {
    children: React.ReactNode;
    actions?: ModalAction[];
    zoomable?: boolean;
}) {
    const transformedChildren = zoomable ? (
        <TransformComponent>
            <div className="relative overflow-hidden h-screen w-screen items-center flex flex-col justify-center">
                {children}
            </div>
        </TransformComponent>
    ) : (
        <div className="relative overflow-hidden h-screen flex flex-col justify-center">
            {children}
        </div>
    );

    const renderButtonContent = (action: ModalAction) => {
        if (action.text) {
            return <span className="fixed mt-4 text-xl">{action.text}</span>; // Use text for the button
        }
        return <span className="material-icons text-3xl">{action.icon}</span>; // Fallback to icon
    };

    const content = (
        <div className="fixed inset-0 bg-black z-50">
            <div className="top-0 left-0 m-1 absolute z-10">
                {actions
                    ?.filter((a) => a.left)
                    .map((action) => (
                        <button
                            type="button"
                            className="ml-1 h-12 w-12 inline-flex items-center justify-center relative"
                            key={action.icon || action.text} // Use text as key if no icon
                            onClick={action.onClick}
                        >
                            {renderButtonContent(action)}
                            {action.indicator && Number.isInteger(action.indicator) ? (
                                <span className="top-0 right-0 block h-4 w-4 text-xs absolute rounded-full bg-primary flex items-center justify-center">
                                    {action.indicator}
                                </span>
                            ) : null}
                        </button>
                    ))}
            </div>
            <div className="top-0 right-0 m-1 absolute z-20">
                {actions
                    ?.filter((a) => !a.left)
                    .map((action) => (
                        <button
                            type="button"
                            className="mr-1 h-12 w-12 inline-flex items-center justify-center relative"
                            key={action.icon || action.text} // Use text as key if no icon
                            onClick={action.onClick}
                        >
                            {renderButtonContent(action)}
                            {action.indicator && Number.isInteger(action.indicator) ? (
                                <span className="top-0 right-0 block h-4 w-4 text-xs absolute rounded-full bg-primary flex items-center justify-center">
                                    {action.indicator}
                                </span>
                            ) : null}
                        </button>
                    ))}
            </div>
            {transformedChildren}
        </div>
    );

    const wheel = { step: 100 };
    const wrappedContent = zoomable ? (
        <TransformWrapper initialScale={1} wheel={wheel}>
            {content}
        </TransformWrapper>
    ) : (
        content
    );

    return <Portal>{wrappedContent}</Portal>;
}
