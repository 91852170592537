import React from 'react';
import { Link } from 'react-router-dom';
import Sites from './Sites';
import Activity from './Activity';
import PhotoUploadButton from '../../components/PhotoUploadButton';
import Profile from './Profile';

const tabs = [
    {
        link: 'activity',
        name: 'Activity',
        default: true,
        Component: Activity,
        floatButton: <PhotoUploadButton />,
    },
    {
        link: 'sites',
        name: 'Sites',
        Component: Sites,
        floatButton: (
            <div className="fixed bottom-0 right-0 m-4 z-20">
                <Link
                    to="/site/add"
                    className="bg-blue-500 h-16 w-16 rounded-full flex items-center justify-center cursor-pointer outline-none shadow-lg"
                >
                    <span className="material-icons text-3xl">add_photo_alternate</span>
                </Link>
            </div>
        ),
    },
    {
        link: 'profile',
        name: 'Profile',
        Component: Profile,
        floatButton: <PhotoUploadButton />,
    },
];

export default tabs;
