import { useCallback, useEffect, useState } from 'react';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useLocal } from '../../../store';

export type Location = { lat: number; lng: number } | undefined;

/**
 * Simply transforms the object into a string (so that locations can be compared)
 */
export function flattenLatLng(location?: Location): string {
    return location ? `${location.lat}/${location.lng}` : '';
}

/**
 * This must only be called after Google Maps has been initialized.
 */
export default function useAddressFromLocation(location?: Location) {
    const latLng = flattenLatLng(location);
    const { data: address, dispatch } = useLocal<string>(`address/${latLng}`, undefined, true);

    useEffect(() => {
        if (latLng) {
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode(
                {
                    location,
                },
                (result) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const geoLocations = result as any[];
                    if (geoLocations?.length > 0) {
                        dispatch(geoLocations[0].formatted_address);
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latLng]);

    return address;
}

/**
 * https://tintef.github.io/react-google-places-autocomplete/docs/get-lat-lng
 * */
export function useLocationFromAddress(address: string): Location {
    const [location, setLocation] = useState<Location>(undefined);

    const getLocationByAddress = useCallback(async () => {
        try {
            const results = await geocodeByAddress(address);
            const { lat, lng } = await getLatLng(results[0]);
            setLocation({ lat, lng });
        } catch (err) {
            setLocation(undefined);
        }
    }, [address]);

    useEffect(() => {
        if (address) {
            getLocationByAddress();
        }
    }, [address, getLocationByAddress]);

    return location;
}
